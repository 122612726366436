
import COLORS from "../../../../common/data/enumColors";
import { TColor } from "../../../../type/color-type";
import React, { ReactNode } from "react";

export type TTags = { text: string; color: TColor };

// 自訂新手教學tags
const TAGS: { [key: string]: TTags } = {
  FACEBOOK: {
    text: "FACEBOOK",
    color: COLORS.INFO.name,
  },
  LINE: {
    text: "LINE",
    color: COLORS.SUCCESS.name,
  },
  LOGIN: {
    text: "登入、註冊",
    color: COLORS.INFO.name,
  },
  STEP1: {
    text: "步驟一",
    color: COLORS.SUCCESS.name,
  },
  STEP2: {
    text: "步驟二",
    color: COLORS.SUCCESS.name,
  },
  STEP3: {
    text: "步驟三",
    color: COLORS.SUCCESS.name,
  },
  STEP4: {
    text: "步驟四",
    color: COLORS.SUCCESS.name,
  },
  STEP5: {
    text: "步驟五",
    color: COLORS.SUCCESS.name,
  },
  STEP6: {
    text: "步驟六",
    color: COLORS.SUCCESS.name,
  },
  STEP7: {
    text: "步驟七",
    color: COLORS.SUCCESS.name,
  },
  STEP8: {
    text: "步驟八",
    color: COLORS.SUCCESS.name,
  },
  STEP9: {
    text: "步驟九",
    color: COLORS.SUCCESS.name,
  },
  STEP10: {
    text: "步驟十",
    color: COLORS.SUCCESS.name,
  },
  STEP11: {
    text: "補充教學",
    color: COLORS.SUCCESS.name,
  },
  STEP12: {
    text: "補充教學",
    color: COLORS.SUCCESS.name,
  },
  STEP13: {
    text: "補充教學",
    color: COLORS.SUCCESS.name,
  },
};

type TCategories = { value: string; text: string };
export const CATEGORIES: { [key: string]: TCategories } = {
  DOCUMENTATION: {
    value: "documentation",
    text: "Documentation",
  },
  SETTINGS: {
    value: "settings",
    text: "Settings",
  },
  COLORS: {
    value: "colors",
    text: "Colors",
  },
};

interface Content {
  key: number;
  title: string;
  description?: ReactNode;
  image: string;
}

interface IData {
  id: number;
  title: string;
  description: string;
  image: string;
  tags: TTags[];
  color: TColor;
  // categories: TCategories[];
  content: Content[];
}

//title是大標題
//dsscription是副標
//image是新手教學圖卡的大標題，也可以換成照片
//content是內文
//tags是標籤
//color選擇圖卡顏色

const data: IData[] = [
  {
    id: 1,
    title: "註冊與登入",
    description: "",
    image: "註冊登入",
    tags: [TAGS.LOGIN],
    color: COLORS.PRIMARY.name,
    // categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
    content: [
      {
        key: 1,
        title: "",
        description: (
          <div>
            請先進入幫賣登入頁面
            <br />
            <a href="https://www.bombmy.live/auth/login" target="_blank">
              https://www.bombmy.live/auth/login
            </a>
            <br />
            輸入您「透過LINE@客服開通」的帳號密碼即可登入。
            <br />
          </div>
        ),
        image: "",
      },

      {
        key: 2,
        title: "⚠️若您尚未擁有幫賣帳號，請先註冊⚠️",
        description: (
          <div>
            1.點選右下角「立即註冊」
            <br />
            2.輸入您的「個人資訊」
            <br />
            3.點選「註冊」即可完成註冊帳號 <br />
            4.並將您註冊的帳號與E-mail傳送至幫賣LINE@客服為您開通 <br />
            5.客服回報開通完成後，即可返回登入頁面，進行登入。 <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new1-1.webp",
      },
      {
        key: 3,
        title: "",
        description: (
          <div>
            ⚠️ 如果您已開通幫賣帳號，CRM為同一組帳號，無需重新註冊 ⚠️ <br />
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new1-2.webp",
      },
    ],
  },
  {
    id: 2,
    title: "上傳直播",
    description: "",
    image: "上傳直播",
    tags: [TAGS.STEP1],
    color: COLORS.SECONDARY.name,
    content: [
      {
        key: 1,
        title: "",
        description: (
          <div>
            <span style={{ color: "red", fontSize: "24px" }}>
              請先依照步驟，壓縮影片檔案後，再上傳至幫賣，以確保觀眾能順暢觀看直播。
            </span>
          </div>
        ),
        image: "",
      },
      {
        key: 1,
        title: "",
        description: (
          <div>
            1.
            開啟FB，選擇一個沒有在使用的粉絲專頁(也可以創建一個新的粉絲專頁)，點擊「相片/影片」。
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new2-1.webp",
      },
      {
        key: 2,
        title: "",
        description: (
          <div>
            2.
            點擊中間區塊「新增相片/影片」後，選擇您要直播的影片檔案，之後點擊下方「發佈」按鈕。
            <br />
            <span style={{ color: "red" }}>
              (發布完畢後，需等待約5~10分鐘，該篇貼文才會出現在粉專。)
            </span>
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new2-2.webp",
      },
      {
        key: 3,
        title: "",
        description: (
          <div>
            3.
            等待貼文發佈完畢後，點擊該貼文左上角的發佈時間，進入到該篇貼文內。
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new2-3.webp",
      },
      {
        key: 4,
        title: "",
        description: <div>4. 點擊右上角「3個點」，並且選擇「下載影片」。</div>,
        image: "/resources/images/bombmy/teaching/new2-4.webp",
      },
      {
        key: 5,
        title: "",
        description: (
          <div>
            5. 點擊左側功能列表「我的影片」
            <br />
            6. 點擊右上角「新增」
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new2-5.webp",
      },
      {
        key: 6,
        title: "",
        description: (
          <div>
            7. 點擊中間虛線範圍「上傳影片檔案」，並選擇您所要上傳的影片檔案。
            <br />
            8. 於下方輸入框內，輸入「影片名稱」
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new2-6.webp",
      },
      {
        key: 7,
        title: "",
        description: <div></div>,
        image: "/resources/images/bombmy/teaching/new2-7.webp",
      },
      {
        key: 8,
        title: "",
        description: <div></div>,
        image: "/resources/images/bombmy/teaching/new2-8.webp",
      },
      {
        key: 9,
        title: "",
        description: (
          <div>
            9.點選「上傳影片」，即可完成上傳直播影片。
            <br />
            ⚠️ 上傳影片過程中，請保持網路穩定，並且不要關閉視窗 ⚠️
            <br />
            ⚠️ 請勿上傳超過500MB的影片，以免造成卡頓或黑畫面。 ⚠️
            <br />
            ⚠️ 影片尺寸大小：640×480~1280×720 ⚠️
            <br />
            ⚠️ 影片支援格式：.MOV .MP4 .WMV .FLV .AVI ⚠️
            <br />
          </div>
        ),
        image: "",
      },
    ],
    // categories: [CATEGORIES.DOCUMENTATION],
    // content:
    //   'Aliquam sodales tempor ullamcorper. Quisque non nibh consequat, dapibus magna et, commodo erat. Proin fringilla nibh mollis, vestibulum dui a, laoreet purus. Aliquam vehicula libero est, ut ornare quam eleifend at. Cras accumsan interdum nulla ut accumsan. Duis ornare, est vel rutrum bibendum, magna odio vehicula sem, non hendrerit dui eros ac erat. Sed et justo ac elit pellentesque ornare sit amet quis magna. Curabitur sagittis, leo pulvinar imperdiet consectetur, libero nisi rhoncus magna, non facilisis tortor mi et felis. Ut aliquet diam at eros faucibus, quis gravida nisl volutpat. Quisque eu nibh orci. Praesent posuere orci ligula, a lacinia mauris venenatis non.',
  },
  {
    id: 3,
    title: "上傳商品",
    description: "",
    image: "上傳商品",
    tags: [TAGS.STEP2],
    color: COLORS.DANGER.name,
    content: [
      {
        key: 1,
        title: "",
        description: (
          <div>
            1. 點選左側功能列表「我的商品」 <br />
            2. 點選右上角「新增」
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new3-1.webp",
      },
      {
        key: 2,
        title: "",
        description: (
          <div>
            3. 點選中間虛線範圍「上傳商品照片」，並選擇您所要上傳的商品圖片。
            <br />
            4. 輸入「商品名稱」和「預設價格」
            <br />
            5. 點選「上傳商品」，即可完成上傳商品
            <br />
            <br />
            <br />
            ⚠️ 此處商品價格為原價，若有限時優惠需求，請至「管理直播」設定 ⚠️
            <br />
            ⚠️ 圖片上傳大小：2MB~10MB ⚠️
            <br />
            ⚠️ 圖片尺寸大小：小於480×480 ⚠️
            <br />
            ⚠️ 圖片支援格式：.JPG .PNG .GIF ⚠️
            <br />
            <br />
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new3-2.webp",
      },
    ],
    // categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
    // content:
    //   'Pellentesque vehicula dolor a nisi tincidunt, vitae ornare enim tempus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nunc viverra neque vel diam hendrerit volutpat. Pellentesque placerat justo a sollicitudin molestie. Etiam bibendum lacus nec tortor viverra, a consectetur neque elementum. Donec at sodales purus. Fusce in urna ac elit pulvinar efficitur in non eros. Praesent eleifend, dolor nec sollicitudin eleifend, est massa egestas metus, vitae aliquet magna erat sed turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris rutrum a augue et suscipit. In id augue ullamcorper libero tincidunt luctus.',
  },
  {
    id: 4,
    title: "建立虛擬使用者",
    description:
      "如果您想重現分享會熱鬧景象，可以使用留言組功能，使用虛擬角色在分享會特定時間段進行留言。",
    image: "虛擬使用者",
    tags: [TAGS.STEP3],
    color: COLORS.SUCCESS.name,
    content: [
      {
        key: 2,
        title: "",
        description: (
          <div>
            1. 點選左側功能列表「虛擬使用者」
            <br />
            2. 點選中間方塊的「加號(+)」
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new4-1.webp",
      },
      {
        key: 2,
        title: "",
        description: (
          <div>
            3. 利用「左右箭頭」選取想要的虛擬使用者頭像 <br />
            4. 輸入該虛擬使用者的「暱稱」 <br />
            5. 點選「新增」，即可完成上傳虛擬使用者 <br />
            <br />
            <br />
            ⚠️如需多個虛擬使用者，請重複執行2~5步驟⚠️ <br />
            <br />
            <br />
            若需要編輯使用者，請直接點選該使用者，修改「頭像」與「暱稱」，點擊下方「儲存」按鈕，即可完成編輯。亦可以點擊「刪除」按鈕，刪除虛擬使用者。
            <br />
            <br />
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new4-2.webp",
      },
    ],
    // categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
    // content:
    //   'Nam vitae blandit elit. Pellentesque efficitur venenatis finibus. Integer at ante rutrum, bibendum ipsum id, viverra mauris. Maecenas rhoncus ligula at lectus ullamcorper, sit amet suscipit massa tristique. Ut mattis feugiat ex, at finibus est ullamcorper in. Maecenas volutpat, odio id aliquam pulvinar, lectus velit malesuada sem, quis rutrum magna lectus quis lacus. Morbi egestas mollis nisl, quis ultrices enim iaculis vitae. Sed maximus blandit mollis. Vestibulum suscipit nibh sit amet vehicula rhoncus. Etiam gravida eu leo ac placerat. Integer vitae nunc ipsum. Phasellus maximus ullamcorper eros, nec blandit ex dignissim non. Donec vulputate molestie risus, vel hendrerit est aliquam vel.',
  },
  {
    id: 5,
    title: "建立留言組",
    description:
      "如果您想重現分享會熱鬧景象，可以使用留言組功能，使用虛擬角色在分享會特定時間段進行留言。",
    image: "留言組",
    tags: [TAGS.STEP4],
    color: COLORS.DARK.name,
    content: [
      {
        key: 2,
        title: "",
        description: (
          <div>
            1. 點選左側功能列表「留言組」 <br />
            2. 點選右上角「新增」or 編輯 現有留言組的「鉛筆」圖示
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new5-1.webp",
      },
      {
        key: 3,
        title: "",
        description: (
          <div>
            3. 輸入該「留言組名稱」 <br />
            4. 選擇要套用的直播影片，並拖曳進度調至指定時段 <br />
            ⚠️僅可選擇已上傳至「我的影片」內的影片⚠️ <br />
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new5-2.webp",
      },
      {
        key: 4,
        title: "",
        description: (
          <div>
            5. 點擊「新增留言組」按鈕，即跳出一位虛擬使用者， <br />
            6.
            依序輸入「留言時間點」，並點擊頭像選擇「使用者頭像」及「發言內容」{" "}
            <br />
            7. 點選「上傳留言組」即可完成上傳留言組 <br />
            ⚠️如需多個留言，請重複執行4~6的部分⚠️ <br />
            <br />
            <br />
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new5-3.webp",
      },
    ],
    // categories: [CATEGORIES.SETTINGS],
    // content:
    //   'Duis posuere risus in enim sagittis, et condimentum ligula eleifend. Phasellus elementum lectus nulla. Curabitur quis vulputate ex. Nunc quis mi nibh. Vivamus sed dictum sem. Suspendisse laoreet nisl sed diam scelerisque, at gravida dui fringilla. Maecenas vel pulvinar mi. Suspendisse suscipit rhoncus dignissim. Phasellus iaculis mattis lacus, id fermentum tortor consectetur nec. Morbi bibendum neque velit, in tincidunt magna molestie vitae. Sed ultrices orci non metus pellentesque consequat. Fusce ut eleifend neque. Nunc bibendum dapibus tortor. Mauris tincidunt auctor eros sed vehicula. Maecenas a lacinia nibh. Nulla in egestas enim.',
  },
  {
    id: 6,
    title: "建立信件模板",
    description: "建立信件模板，傳送分享會前、後的提醒簡訊/郵件。",
    image: "信件模板",
    tags: [TAGS.STEP5],
    color: COLORS.WARNING.name,
    content: [
      {
        key: 0,
        title: "",
        description: (
          <div>
            ⚠️新用戶請於建立信件模板前，請先依照下圖，進行點數儲值，才可以寄送提醒信件給顧客。⚠️<br />
            ⚠️若您已綁定電子豹surenotify方案，依舊可以繼續使用該方案，無須另外進行儲值。⚠️
             <br />
          </div>
        ),
        image: "",
      },
      {
        key: 1,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new6-6.webp",
      },
      {
        key: 2,
        title: "",
        description: (
          <div>
            1. 點選左側功能列表「信件模板」
            <br />
            2.
            點選右上角「新增」⚠️以下共分為兩個部分，分別有簡訊模板以及電子信箱模板⚠️
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new6-1.webp",
      },
      {
        key: 3,
        title: "簡訊模板",
        description: (
          <div>
            3. 輸入「模板名稱」 <br />
            4. 點選「簡訊」按鈕 <br />
            5. 輸入「手機簡訊內文」
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new6-2.webp",
      },
      {
        key: 4,
        title: "",
        description: (
          <div>
            6. 點選「預覽」，可查看內容是否正確 <br />
            7. 點選「上傳模板」即可完成上傳
            <br />
            ⚠️ 簡訊有字數限制，若超過69字(半形)，則計算為兩封簡訊費用 ⚠️ <br />
            ⚠️ 分享會連結長度約為40字(半形)，請注意字數的限制 ⚠️ <br />
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new6-3.webp",
      },
      {
        key: 5,
        title: "電子信箱模板",
        description: (
          <div>
            3. 輸入「模板名稱」
            <br />
            4. 點選「電子郵件」
            <br />
            5. 輸入「電子郵件主旨」
            <br />
            6. 輸入「電子郵件內文」
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new6-4.webp",
      },
      {
        key: 6,
        title: "",
        description: (
          <div>
            7. 點選「預覽」查看格式是否正確
            <br />
            8. 點選「上傳模板」即可完成上傳信箱模板
            <br />
            <br />
            ⚠️ 分享會連結長度約為40字(半形)，請注意字數的限制 ⚠️
            <br />
            <br />
            參數對應中文名稱
            <br /> <br />
            {`{{`} name {`}}`} = 姓名
            <br />
            {`{{`} phone {`}}`} = 電話
            <br />
            {`{{`} email {`}}`} = 信箱
            <br />
            {`{{`} session_name {`}}`} = 場次名稱
            <br />
            {`{{`}live_name {`}}`} = 直播名稱
            <br />
            {`{{`} live_link {`}}`} = 直播連結
            <br />
            {`{{`} live_begin_date {`}}`} = 直播開始時間
            <br />
            <br />
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new6-5.webp",
      },
    ],
    // categories: [CATEGORIES.SETTINGS],
    // content:
    //   'Maecenas id mollis turpis, non tincidunt tellus. Maecenas facilisis leo at mi accumsan tempor. Integer auctor tellus ut mi euismod, id tempus ex tempus. Curabitur feugiat arcu sem, et ultricies ligula feugiat at. Nullam nec condimentum elit, quis varius nisl. Sed venenatis at justo quis ornare. Ut sed suscipit ipsum. Aenean tempus neque eu ligula cursus convallis. Morbi ornare justo a ipsum blandit, quis varius massa euismod. Nam in orci enim. Vestibulum facilisis egestas lacus commodo vestibulum. Maecenas laoreet tincidunt dui, vel aliquam neque vestibulum vel. Pellentesque condimentum ullamcorper eros. Pellentesque bibendum convallis sem sit amet porta.',
  },
  {
    id: 7,
    title: "製作報名頁",
    description: "設定報名頁基本資訊，提供顧客選擇、報名直播場次。",
    image: "報名頁",
    tags: [TAGS.STEP6],
    color: COLORS.PRIMARY.name,
    content: [
      {
        key: 1,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new7-1.webp",
      },

      {
        key: 2,
        title: "",
        description: (
          <div>
            1. 點選左側功能列表「管理報名」
            <br />
            2. 點選右上角「新增」
            <br />
            3. 選擇「報名頁類型」
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new7-7.webp",
      },
      {
        key: 3,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new7-2.webp",
      },
      {
        key: 4,
        title: "",
        description: (
          <div>
            3.
            選擇「主題顏色」，並輸入「報名腳本」名稱、「搶購倒數」、「置頂文字」、「自訂網址」
            <br />
            4.
            點選虛線範圍「管理商品海報」，點選「新增海報」，並上傳海報圖片，拖曳圖片調整海報順序。右側垃圾桶按鈕，可以刪除該張海報。亦可再點擊「新增海報」，上傳新的海報圖片。
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new7-3.webp",
      },
      {
        key: 5,
        title: "",
        description: (
          <div>
            ⚠️「新增事件」功能，需先串接Facebook像素才可使用。 ⚠️ <br /> <br />
            5. 點擊「新增事件」後，輸入事件名稱，並拖曳該事件到指定位置即可。
            <br /> <br />
            ⚠️背景圖片為報名頁左右兩邊的區塊，如需上傳背景圖片，
            請點選「上傳報名背景」，並上傳背景即可 ⚠️ <br />
            ⚠️ 主題顏色為整個報名頁的主體顏色架構 ⚠️ <br />
            ⚠️ 海報圖片上傳大小：小於40MB、海報圖片支援格式：.JPG .PNG .GIF ⚠️
            <br />
            ⚠️ 背景圖片上傳大小：2MB~10MB、背景圖片尺寸大小：小於1080×1920 ⚠️
            <br />
            ⚠️ 背景圖片支援格式：.JPG .PNG .GIF ⚠️
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new7-4.webp",
      },
      {
        key: 6,
        title: "",
        description: (
          <div>
            6.點選「報名、訂單」，切換報名頁設定。
            <br /> <br />
            請輸入報名注意事項 <br />
            ⚠️
            如需報名成功即寄送簡訊跟信件，請選擇先前設定完成的簡訊跟信件模板，
            即可按照先前格式做發送 ⚠️
            <br />
            ⚠️
            訂單前綴為客戶做下單後，跑出來相對應的訂單前三個字元，(EX：ABC、MMM、ZYX)
            如未設定即為亂碼，如需統一觀看即須設定 ⚠️
            <br />
            ⚠️ 訂單前綴限制字元：大寫英文、半形、3位 ⚠️
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new7-5.webp",
      },
      {
        key: 7,
        title: "",
        description: (
          <div>
            7. 點選「設定金流」並輸入對應資訊。
            <br />
            所有放入該報名頁的分享會場次，都只有該報名頁有設定的金流，才可以使用。
            <br />
            請務必在報名頁設定好金流，讓您的直播可以順利結帳。
            <br />
            ⚠️在設定金流裡如需使用"銀行轉帳"以外的功能，須先到 CRM ramata
            設定第三方金流⚠️
            <br />
            <a href="https://crm.ramatetech.com/" target="_blank">
              CRM ramata 連結
              <br />
            </a>
            <a
              href="https://hackmd.io/@JeremyLiu0420/SyM2-yIAj"
              target="_blank"
            >
              CRM ramata 操作手冊連結
              <br />
            </a>
            <br />
            8. 點選「設定物流」，並設定手續費
            <br />
            9. 點選「上傳報名」，即可完成上傳報名頁
            <br /> <br /> <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new7-6.webp",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
  {
    id: 8,
    title: "建立直播",
    description: "建立直播場次，讓顧客參與分享會。",
    image: "建立直播",
    tags: [TAGS.STEP7],
    color: COLORS.DANGER.name,
    content: [
      {
        key: 2,
        title: "",
        description: (
          <div>
            1. 點選左側功能列表「管理直播」 <br />
            2. 點選右上角「新增」
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new8-1.webp",
      },
      {
        key: 3,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new8-2.webp",
      },
      {
        key: 4,
        title: "點選「影片、照片」切換至該頁面",
        description: (
          <div>
            3. 點選上傳直播封面
            <br />
            ⚠️ 封面圖片上傳大小：2MB~10MB ⚠️
            <br />
            ⚠️ 封面圖片尺寸大小：小於1080×1920 ⚠️ <br />
            ⚠️ 封面圖片支援格式：.JPG .PNG .GIF ⚠️ <br />
            4. 點選上傳廠商頭貼 <br />
            ⚠️ 頭貼圖片上傳大小：小於2MB ⚠️ <br />
            ⚠️頭貼圖片尺寸大小：小於480×480⚠️ <br />
            ⚠️頭貼圖片支援格式：.JPG .PNG .GIF⚠️
            <br />
            5. 點選「直播影片」
            <br />
            6. 輸入廠商名稱 <br />
            7. 輸入直播名稱、直播內文 等… <br />
            直播名稱：直播的標題 <br />
            直播內文：直播內的說明文字 <br />
            虛擬觀眾數：額外新增虛擬觀看人數 <br />
            留言組：套用先前設定的留言組 <br />
            直播結束的文字：結束後會顯示於畫面的文字
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new8-3.webp",
      },
      {
        key: 5,
        title: "點選「留言、日期」切換至該頁面",
        description: (
          <div>
            1. 輸入「禁⽌關鍵字」若完全符合變成***
            <br />
            2.
            設定「排程日期及時間」，若要新增多個時間，可點擊「+」，同時新增多場時間。
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new8-4.webp",
      },
      {
        key: 6,
        title: "點選「訂單、信件」切換至該頁面",
        description: (
          <div>
            1. 輸入訂單說明
            <br />
            2. 選擇直播前後是否寄出信件，需透過信件模板進行設定。
            <br />
            3. 如需關閉觀眾互動留⾔，請將按鈕開啟
            <br />
            4. 若需使用分頁式下單功能，請將按鈕開啟
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new8-5.webp",
      },
      {
        key: 7,
        title: "",
        description: (
          <div>
            5.
            點選「新增商品」，並選擇商品、設定商品原價與特價、顯示於直播的時間。
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new8-6.webp",
      },
      {
        key: 8,
        title: "",
        description: (
          <div>
            6.
            若須設定增援，請點選「新增增援」，並選擇虛擬使用者、下單時間、下單數量，設定完畢可在下方預覽。
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new8-7.webp",
      },
      {
        key: 9,
        title: "",
        description: (
          <div>
            7.
            若需要使用自訂欄位，請點選「新增自訂欄位」，選擇格式，並勾選是否為必填項目，接著輸入標題及內文
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new8-8.webp",
      },
      {
        key: 10,
        title: "",
        description: (
          <div>
            8. 點選「上傳直播腳本」，即可完成直播上傳。
            <br />
            ⚠️ 自訂欄位為顧客購買時，下單頁面會出現相對應的選項及問題 ⚠️
            <br />
            ⚠️
            若要將多場直播放置於同⼀個報名⾴⾯，請先製作多個直播，再回到報名⾴⾯設定
            ⚠️
            <br /> <br /> <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new8-9.webp",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
  {
    id: 9,
    title: "管理直播功能介紹",
    description: "管理直播基本功能介紹",
    image: "管理直播",
    tags: [TAGS.STEP7],
    color: COLORS.DANGER.name,
    content: [
      {
        key: 2,
        title: "",
        description: (
          <div>
            1. 點選左側功能列表「管理直播」
            <br />
            2. 點選指定直播的「3個點」，會看到3項功能。
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new8-10.webp",
      },
      {
        key: 3,
        title: "",
        description: (
          <div>
            3.「直播後台」功能，可以監視當前直播狀況，也可以於直播結束後進行設定。
            <br />
            右側聊天室輸入框，可透過人物頭像按鈕選擇角色，進行即時回覆。 <br />
            顧客留言的頭像旁，有3顆按鈕，喇叭符號為黑名單功能，詳細使用請參考「黑名單」教學文章；右側「+」可以將該則留言加入留言組；顧客姓名右方「箭頭」，可私訊回覆這位顧客，其他顧客看不到您發送的這則留言回覆。
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new8-11.webp",
      },
      {
        key: 4,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new8-12.webp",
      },
      {
        key: 5,
        title: "",
        description: (
          <div>
            4.「檢視報名」功能，上方可搜尋「報名時間區間」，右側3個按鈕，分別為修改顧客資訊、複製顧客專屬連結、取消顧客提醒E-mail與簡訊、右上角可以切換顯示模式。
            切換顯示模式後，可以檢視顧客的觀看狀況，有觀看的時間點，以及總共觀看的時長。
            <br />
            5.「複製」功能，可以直接複製該場直播，但要重新設定時間，請特別注意。
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new8-13.webp",
      },
    ],
  },
  {
    id: 10,
    title: "更新報名頁",
    description: "建立好直播場次後，將直播場次放入報名頁供顧客選擇報名。",
    image: "更新報名頁",
    tags: [TAGS.STEP8],
    color: COLORS.SECONDARY.name,
    content: [
      {
        key: 2,
        title: "",
        description: (
          <div>
            1. 點選左側功能列表「管理報名」
            <br />
            2. 點選指定報名頁的「修改🖋」
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new9-1.webp",
      },
      {
        key: 3,
        title: "",
        description: (
          <div>
            3. 於右側輸入該場次名稱，建議使用 活動名稱加上日期
            (例：幫賣分享會7/20 晚上場) <br />
            4.
            輸入場次內文，建議再次提醒顧客重要事項(例：直播開始時將透過email及簡訊通知您){" "}
            <br />
            5. 點擊「綁定直播」按鈕，選擇要綁定的直播場次。
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new9-2.webp",
      },
      {
        key: 4,
        title: "",
        description: (
          <div>
            6.點擊上方「新增」按鈕，該場次就會出現於下方，您可以再次確認，進行編輯、刪除。
            <br />
            7.確認報名頁面其餘設定，都沒問題後，點擊右上角「更新報名」，就完成報名頁更新了。
            <br /> <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new9-3.webp",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
  {
    id: 11,
    title: "廠商設定",
    description: "廠商基本設定與廣告像素串接。",
    image: "廠商設定",
    tags: [TAGS.STEP9],
    color: COLORS.DARK.name,
    content: [
      {
        key: 1,
        title: "",
        description: (
          <div>
            1. 輸入「廠商名稱」並設定「頭貼」
            <br />
            2. 輸入「Facebook 像素追蹤 id」及 「API
            KEY」（需進入FB廣告後台設定）
            
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new11-1.webp",
      },
      {
        key: 2,
        title: "",
        description: (
          <div>
            <br />
            3. 輸入 「GTM ID」 及 「GTAG」（綁定Google Tag Manager所需代碼）
            <br />
            4. 輸入「使用者聯繫網址」（可放上粉絲專頁網址、官網網址 等…）
            <br />
            5.輸入「電子豹的寄件人 EMAIL」 ，並且點擊右上角「儲存」
            按鈕，最後點擊左側「接收信件寄送狀態」即可完成（電子豹串接完成，才可寄送提醒簡訊與信件）。
            <br />
            6.修改完所有資料，請記得點擊右上角「儲存」 按鈕。
            <br /> <br />
           
            <br />
            更改密碼，需要透過CRM介面進行操作，進入CRM頁面後，點擊「左下角您的頭像」，下方會跳出「設定」按鈕，進入設定頁面後，點擊「個人資訊」，即可看到更改密碼功能。{" "}
            <br />
            「CRM密碼」與「幫賣密碼」會同步進行變更，請務必留意。
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new11-2.webp",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
  {
    id: 12,
    title: "黑名單功能",
    description: "若您想禁止不當留言時，可以使用黑名單功能。",
    image: "黑名單",
    tags: [TAGS.STEP11],
    color: COLORS.SUCCESS.name,
    content: [
      {
        key: 1,
        title: "",
        description: (
          <div>
            使用黑名單功能，請先透過左側功能列表，進入「管理直播」頁面。
            <br />
            黑名單功能僅可以在「直播過程中」 以及 「直播結束後」使用。
          </div>
        ),
        image: "",
      },
      {
        key: 2,
        title: "",
        description: (
          <div>
            1. 點選該場直播右側的「3個點」並選擇「直播後台」 <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new10-1.webp",
      },
      {
        key: 3,
        title: "",
        description: (
          <div>
            2.
            於右側聊天室，選取想禁止的留言，點擊左側的「喇叭」按鈕，「喇叭」按鈕前方會變成「X」，並且看到左下方「黑名單」列表出現該位顧客名稱。{" "}
            <br />
            3.
            勾選是要「永久禁言」或「解除禁言」，若不選擇，即為初始設定的「單場禁言」。
            <br />
            4.接著點選左側功能列表「黑名單」，就可以看到被加入黑名單的顧客，點擊「顧客姓名」，即可觀看該顧客資料。
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new10-2.webp",
      },
      {
        key: 4,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new10-3.webp",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },

  {
    id: 13,
    title: "聯盟行銷",
    description:
      "當您有需要讓其他夥伴一起推廣產品、分享會時，您即可使用聯盟行銷功能，會自動幫您計算該位夥伴推薦多少人參與分享會與購買。",
    image: "聯盟行銷",
    tags: [TAGS.STEP11],
    color: COLORS.WARNING.name,
    content: [
      {
        key: 1,
        title: "",
        description: <div>1. 點選左側功能列表「聯盟行銷」</div>,
        image: "/resources/images/bombmy/teaching/new12-1.webp",
      },
      {
        key: 2,
        title: "",
        description: (
          <div>
            2.
            點選右上角「新增夥伴」，依序輸入「夥伴名稱」、「電子信箱」、「聯絡電話」、「設定密碼」、「再次輸入密碼」。
            <br />
            3. 建立完成「夥伴」後，才可以進行「新增報名頁」。
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new12-2.webp",
      },
      {
        key: 3,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new12-3.webp",
      },
      {
        key: 4,
        title: "",
        description: (
          <div>
            點擊按鈕設定報名頁資訊：
            <br />
            「推廣者」請直接選取創建完成的夥伴。
            <br />
            「綁定報名頁」欄位，會出現目前擁有的報名頁面，直接選取即可。
            <br />
            「自訂網址」您可自行設定代號。（例如：同仁的編號為ABC001，直接輸入ABC001，下方可以預覽連結網址，即可讓您快速辨識該網址是提供給哪個同仁使用。）
            <br />
            「連結有效日期」為該連結的報名、訂單計算的有效時間，請填寫結束計算的時間。（例如：您想計算該位同仁至7月底的報名與成交數，結束日期請選擇7/31。）
            <br />
            4.
            上述設定都完成後，直接點擊下方「新增報名頁」即可完成聯盟行銷設定。
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new12-4.webp",
      },
      {
        key: 5,
        title: "",
        description: (
          <div>
            5.
            點擊夥伴後方的箭頭按鈕，即可查看該夥伴的報名頁成效，有進站、報名、下單、完款可以查看。{" "}
            <br />
            夥伴後方符號： <br />
            「鉛筆」可以編輯夥伴資訊；「信件」可以寄送郵件給夥伴查看成績；「垃圾桶」可以刪除該位夥伴。{" "}
            <br />
            報名頁後方符號： <br />
            「鉛筆」可以編輯報名頁設定；「放大鏡」可查看詳細項目；「垃圾桶」可以刪除報名頁資訊。{" "}
            <br /> <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new12-5.webp",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
  {
    id: 14,
    title: "寄送簡訊、信箱",
    description:
      "若您需要寄送簡訊或email給予報名分享會的顧客，您可以透過「寄送簡訊、信箱」功能傳送簡訊/郵件。",
    image: "簡訊、信箱",
    tags: [TAGS.STEP12],
    color: COLORS.DANGER.name,
    content: [
      {
        key: 0,
        title: "",
        description: (
          <div>
          ⚠️新用戶請於建立信件模板前，聯繫
          <a href="https://lin.ee/xXVJ0DL" target="_blank" rel="noreferrer">
          幫賣LINE@
          </a>
          進行點數儲值，才可以寄送提醒信件給顧客
          若您已綁定電子豹surenotify方案，依舊可以繼續使用該方案，無須另外進行儲值。⚠️
          <br /> <br />
        </div>
        ),
        image: "",
      },
      {
        key: 1,
        title: "",
        description: (
          <div>
            1. 點選左側功能列表「寄送簡訊、信箱」 <br />
            2. 點選右上角「新增」 <br />
            3. 點選”綁定直播”輸入框，選擇您要綁定的直播場次。 <br />
            4.選取「寄送對象」，若要寄送該場次全體報名顧客，請點選「全部」；若要單獨寄送個別顧客，請點擊「選擇的對象」，並在該顧客前方的方框內打勾，即可選擇您所要寄送的顧客。{" "}
            <br />
            ⚠️ 左下角有切換頁面選項，右上角也有搜尋功能可以使用 ⚠️ <br />
            5.選擇寄送對象後，請點擊右下角「下一步」
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new13-1.webp",
      },
      {
        key: 2,
        title: "",
        description: (
          <div>
            6.切換至編輯訊息內容頁面後，點擊「簡訊範本」，就可以選擇事先建立好的信件模板，下方輸入框可以自行輸入與編輯；點擊「信件範本」，即可選擇email信件模板進行套用，信箱主旨請輸入該封email您所想呈現的標題，下方輸入框可以自行輸入與編輯。
            <br />
            7.可以點擊左上角「回上一步」，重新選取您所要寄送的顧客；
            <br /> <br /> <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new13-2.webp",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
  {
    id: 15,
    title: " CRM 第三方服務設定",
    description: "",
    image: "第三方服務",
    tags: [TAGS.STEP12],
    color: COLORS.SUCCESS.name,
    content: [
      {
        key: 1,
        title: "",
        description: <div>1. 點選左側功能列表「CRM平台」</div>,
        image: "/resources/images/bombmy/teaching/new14-1.webp",
      },
      {
        key: 2,
        title: "",
        description: (
          <div>
            2.輸入與幫賣一樣的「帳號與密碼」，即可登入CRM平台。 <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new14-2.webp",
      },
      {
        key: 3,
        title: "",
        description: (
          <div>
            3.點擊「使用者名稱」
            <br /> 4.點擊「設定」
            <br />
            5.點擊「第三方服務」，就可以看到合作的廠商，串接收款金流與電子發票都可以在這邊設定。
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new14-3.webp",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
  {
    id: 16,
    title: "CRM 附屬帳號設定",
    description: "",
    image: "CRM帳號",
    tags: [TAGS.STEP12],
    color: COLORS.SECONDARY.name,
    content: [
      {
        key: 1,
        title: "",
        description: <div>1. 點選左側功能列表「CRM平台」</div>,
        image: "/resources/images/bombmy/teaching/new14-1.webp",
      },
      {
        key: 2,
        title: "",
        description: (
          <div>2.輸入與幫賣一樣的「帳號與密碼」，即可登入CRM平台。</div>
        ),
        image: "/resources/images/bombmy/teaching/new14-2.webp",
      },
      {
        key: 3,
        title: "",
        description: (
          <div>
            3.點選左側功能列表「附屬帳號設定」
            <br /> 4.點擊右上角「新增」
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new15-1.webp",
      },
      {
        key: 4,
        title: "",
        description: <div>5.輸入該附屬帳號的「帳號資訊」</div>,
        image: "/resources/images/bombmy/teaching/new15-2.webp",
      },
      {
        key: 5,
        title: "",
        description: (
          <div>
            6.設定該附屬帳號的「幫賣系統權限」與「CRM系統權限」。
            <br />
            ⚠️若隱藏該附屬帳號的「幫賣系統」，將無法登入幫賣⚠️ <br /> <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new15-3.webp",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
  {
    id: 17,
    title: "建立銷售頁",
    description:
      "若想要建立一個可供顧客直接下單購買的頁面，請使用建立銷售頁的功能。",
    image: "銷售頁",
    tags: [TAGS.STEP12],
    color: COLORS.PRIMARY.name,
    content: [
      {
        key: 1,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new7-1.webp",
      },
      {
        key: 2,
        title: "",
        description: (
          <div>
            1. 點選左側功能列表「管理報名」 <br />
            2. 點選右上角「新增」 <br />
            3. 選擇「銷售頁類型」 <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new16-1.webp",
      },
      {
        key: 3,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new7-3.webp",
      },
      {
        key: 4,
        title: "",
        description: (
          <div>
            4. 上傳「銷售頁背景」、「銷售頁海報」，並且輸入「銷售頁基本資訊」
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new16-2.webp",
      },
      {
        key: 5,
        title: "",
        description: (
          <div>
            5.
            切換至「銷售說明」頁面，填寫「銷售頁內文」、「訂單說明」、「訂單前綴」、「分頁式下單」。
            <br />
            6.
            點擊「新增商品」，選擇商品並輸入「商品資訊」，請記得按右上角「儲存」，會看到該商品會出現在下方。
            <br />
            7.
            若需要自訂欄位，點擊「新增自訂欄位」，並選擇需要的類型，是否為「必填」欄位，一樣要記得按下右上角的「儲存」。
            <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new16-3.webp",
      },
      {
        key: 6,
        title: "",
        description: (
          <div>
            8. 點選「設定金流」並輸入對應資訊。 <br />
            所有放入該銷售頁的分享會場次，都只有該銷售頁有設定的金流，才可以使用。
            <br />
            請務必在銷售頁設定好金流，讓您的直播可以順利結帳。 <br />
            ⚠️在設定金流裡如需使用"銀行轉帳"以外的功能，須先到 CRM ramata
            設定第三方金流⚠️ <br />
            <a href="https://crm.ramatetech.com/" target="_blank">
              CRM ramata 連結
              <br />
            </a>
            <a
              href="https://hackmd.io/@JeremyLiu0420/SyM2-yIAj"
              target="_blank"
            >
              CRM ramata 操作手冊連結
              <br />
            </a>
            <br />
            8. 點選「設定物流」，並設定手續費
            <br />
            9. 點選「上傳報名」，即可完成上傳銷售頁
            <br /> <br /> <br />
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new16-4.webp",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
  {
    id: 18,
    title: "幫賣串接GTM，讓GA4接收「報名次數」",
    description:
      "操作以下步驟前，請先確認您已串接獨立網域，若您尚未串接獨立網域，請先至幫賣LINE@客服詢問。",
    image: "串接GTM",
    tags: [TAGS.STEP12],
    color: COLORS.PRIMARY.name,
    content: [
      {
        key: 1,
        title: "",
        description: (
          <div>
            <h3>1、建立Google Tag Manager帳戶</h3><br></br>
            <span>
            請登進入<a href="https://tagmanager.google.com/#/home">Google Tag Manager</a><br/>
            建立一個專屬帳戶，目標廣告平台記得選”網路”。
            </span>
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new18-1.png",
      },
      {
        key: 2,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-2.png",
      },
      {
        key: 3,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-3.jpg",
      },
      {
        key: 4,
        title: "",
        description:"",
        image: "/resources/images/bombmy/teaching/new18-4.png",
      },
      {
        key: 5,
        title: "",
        description: (
          <div>
            ⚠️按建立後會出現這個合約條款，請勾選再點右上角”是”。⚠️
          </div>
        ),
        image: "",
      },
      {
        key: 6,
        title: "",
        description: (
          <div>
           <h3>2、進入GA4，建立串流並找到評估ID(很重要)</h3><br></br>
           ⚠️若您尚未擁有Google Analytics(分析)帳戶，請先點擊<a href="https://support.google.com/analytics/answer/9304153?hl=zh-Hant&ref_topic=12156336,12153943,2986333,&sjid=17246360584240649404-AP&visit_id=638532625420409326-2037274449&rd=1">該篇教學</a>，建立GA4帳戶。<br/>
           接著進入 <a href="https://analytics.google.com/">Google Analytics(分析)</a> 頁面⚠️
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new18-5.png",
      },
      {
        key: 7,
        title: "",
        description: (
          <div>
           左下角”管理”，”資料串流”，選擇相對應的資料進行串流(切換到網頁比較好找)，右邊箭頭點開，就可以看到評估ID，點選右邊複製該評估ID。<br/>
           如果沒有創建過，請先點擊右上角”新增串流”，選擇”網站”，並且貼上您的幫賣報名頁網址，再輸入”串流名稱”，最後點擊”建立串流”即可。<br/>
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new18-6.png",
      },
      {
        key: 8,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-7.png",
      },
      {
        key: 9,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-8.png",
      },
      {
        key: 10,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-9.png",
      },
      {
        key: 11,
        title: "",
        description: (
          <div>
            <h3>3、將GTM ID與G TAG貼到 幫賣的”廠商設定”</h3><br></br>
            把複製出來的評估ID貼到幫賣”廠商設定”的G TAG欄位。<br/>
            GTM ID請切換到Google Tag Manager頁面，右上角即可看到GTM ID。<br/>
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new18-10.png",
      },
      {
        key: 12,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-11.png",
      },
      {
        key: 13,
        title: "",
        description: (
          <div>
            <h3>4、在GTM新增Init代碼</h3><br></br>
            接著進入GTM該帳戶，左側功能列表，切換到”代碼”，右上角有”新增”，先更改代碼名稱，並在上方”代碼設定”區塊，點擊一下，選擇”Google代碼”，將上方的複製出來的“評估ID”貼到欄位內。<br/>
            觸發條件欄位，找到”Initialization - All Pages”，都確認沒問題後，點擊右上角”儲存”。<br/>
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new18-12.png",
      },
      {
        key: 14,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-13.png",
      },
      {
        key: 15,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-14.png",
      },
      {
        key: 16,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-15.png",
      },
      {
        key: 17,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-16.png",
      },
      {
        key: 18,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-17.png",
      },
      {
        key: 19,
        title: "",
        description: (
          <div>
            <h3>5、在GTM新增GA4代碼</h3><br></br>
            右上角有”新增”，在上方”代碼設定”區塊，點擊一下，選擇”Google Analytics” {">"} “Google Analytics：GA4事件”，將上方的複製出來的“評估ID”貼到欄位內。
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new18-18.png",
      },
      {
        key: 20,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-19.png",
      },
      {
        key: 21,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-20.png",
      },
      {
        key: 22,
        title: "",
        description: (
          <div>
            <h3>6、設定代碼的觸發條件</h3><br></br>
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new18-21.png",
      },
      {
        key: 23,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-22.png",
      },
      {
        key: 24,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-23.png",
      },
      {
        key: 25,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-31.png",
      },
      {
        key: 25,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-24.png",
      },
      {
        key: 26,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-25.png",
      },
      {
        key: 27,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-26.png",
      },
      {
        key: 28,
        title: "",
        description: (
          <div>
            <h3>7、確認代碼與觸發條件都正確</h3><br></br>
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new18-27.png",
      },
      {
        key: 29,
        title: "",
        description: (
          <div>
           <h3>8、提交GTM代碼</h3><br></br>
          </div>
        ),
        image: "/resources/images/bombmy/teaching/new18-28.png",
      },
      {
        key: 30,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-29.png",
      },
      {
        key: 31,
        title: "",
        description: "",
        image: "/resources/images/bombmy/teaching/new18-30.png",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
  {
    id: 19,
    title: "幫賣金流統計表格",
    description:
      "第三方金流統計表",
    image: "金流統計",
    tags: [TAGS.STEP12],
    color: COLORS.PRIMARY.name,
    content: [
      {
        key: 1,
        title: "",
        description: (
          <div>
            <div className='table-responsive'>
							<table className='table align-middle'>
								<thead>
									<tr style={{fontSize:20}}>
										<th scope='col'>金流商</th>
                    <th scope='col'>金流設定費</th>
										<th scope='col'>單月額度</th>
										<th scope='col'>信用卡分期手續費</th>
										<th scope='col'>虛擬匯款帳戶</th>
									</tr>
								</thead>
								<tbody style={{fontSize:16}}>
									<tr>
										<th>藍新NewebPay</th>
                    <td>無</td>
                    <td>個人：20萬<br/>企業：60萬</td>
                    <td>(國內卡)一次付清：2.8%<br/>
                        (國外卡)一次付清：3.5%<br/>
                        3期：3%<br/>
                        6期：3.5%<br/>
                        12期：7%<br/>
                        18期：9%<br/>
                        24期：12%<br/>
                        30期：15%<br/>
                        ATM轉帳：1%<br/>
                    </td>
                    <td>有</td>
									</tr>
                  <tr>
										<th>PChomePay 支付連</th>
                    <td>無</td>
                    <td>30萬</td>
                    <td>(國內卡)一次付清：2.0%<br/>
                        3期：2.3%<br/>
                        6期：3.5%<br/>
                        12期：6%<br/>
                        18期：8%<br/>
                        24期：9%<br/>
                    </td>
                    <td>有</td>
									</tr>
                  <tr>
										<th>統一金流Payuni</th>
                    <td>無</td>
                    <td>個人：20萬<br/>企業：100萬</td>
                    <td>(國內卡)一次付清：2.8%<br/>
                        (國外卡)一次付清：3.5%<br/>
                        (銀聯卡)一次付清：2.8%<br/>
                        3期：2.9%<br/>
                        6期：3.5%<br/>
                        9期：4%<br/>
                        12期：6.5%<br/>
                        18期：9%<br/>
                        24期：11%<br/>
                        30期：13%<br/>
                    </td>
                    <td>有</td>
									</tr>
                  <tr>
										<th>立吉富PayNow</th>
                    <td>無</td>
                    <td>個人：6萬<br/>企業：20萬起</td>
                    <td>
                        3期：2.2%<br/>
                        6期：3.5%<br/>
                        12期：5.75%<br/>
                        18期：8.74%<br/>
                        24期：9.5%<br/>
                    </td>
                    <td>有</td>
									</tr>
                  <tr>
										<th>綠界ECPay</th>
                    <td>無</td>
                    <td>個人：30萬<br/>企業：議定</td>
                    <td>(國內卡)一次付清：2.75%<br/>
                        其餘全部 依雙方議定<br/>
                    </td>
                    <td>有</td>
									</tr>
                  <tr>
										<th>歐買尬FunPoint</th>
                    <td>第一年免年費，<br></br>
                    收取一次性設定費5000元</td>
                    <td>個人：30萬<br/>企業：50萬</td>
                    <td>幫賣特約：<br/>
                        一次付清：2.25%<br/>
                        3期：2.5%<br/>
                        6期：3.5%<br/>
                        12期：6.5%<br/>
                        18期：9%<br/>
                        24期：10.5%<br/>
                    </td>
                    <td>有</td>
									</tr>
                  <tr>
										<th>紅陽支付SunPay</th>
                    <td>3年型：NT$22,000（服務費/年費），<br/>NT$800（徵信費），<br/>NT$7,000（設定費）。<br/>
                        1年型：NT$12,000（服務費/年費），<br/>NT$800（徵信費），<br/>NT$4,000（設定費）。</td>
                    <td>個人：30萬<br/>企業：50萬</td>
                    <td>
                    (國內卡)一次付清：雙方議定<br/>
                    (國外卡)一次付清：3.5%<br/>
                    3期：3%<br/>
                    6期：4%<br/>
                    12期：6%<br/>
                    18期：9%<br/>
                    24期：10%<br/>
                    30期：13%<br/>
                    </td>
                    <td>有</td>
									</tr>
								</tbody>
							</table>
						</div>
          </div>
        ),
        image: "",
      },
      {
        key: 2,
        title: "",
        description: (
          <div>
            <span>
            如果是個人賣家，建議先申請PCHome PAY支付連，<br/>
            因為支援多家信用卡，並且不用收取申請費用，單月上限為30萬，<br/>
            對於個人賣家而言，應該算是很夠用了。會超出額度的話，就可以考慮申請公司，再去提高額度上限。<br/>
            申請請點此連結：<a href='https://web.pchomepay.com.tw/'>https://web.pchomepay.com.tw/</a>
            </span>
          </div>
        ),
        image: "",
      },
      {
        key: 3,
        title: "",
        description: (
          <div>
            <span>
            如果擁有公司，建議申請FunPoint，幫賣有專屬的金流優惠專案，手續費比較自行申請更低，這是幫賣用戶專屬的福利喔！<br/>
            一次付清支援28家信用卡，分期也有支援玉山、台新、永豐、中信。未來還會陸續新增聯邦、星展、國泰、富邦 等等信用卡。<br/>
            公司可收取50萬元，更可以申請提高額度。<br/>
            如果幫賣客戶有需要申請，請聯繫幫賣LINE@：@168lcdem
            </span>
          </div>
        ),
        image: "",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
];

export default data;
