import React from "react";
import COLORS from "../../../../common/data/enumColors";
import { TColor } from "../../../../type/color-type";

export type TTags = { text: string; color: TColor };
const TAGS: { [key: string]: TTags } = {
  FACEBOOK: {
    text: "FACEBOOK",
    color: COLORS.INFO.name,
  },
  LINE: {
    text: "LINE",
    color: COLORS.SUCCESS.name,
  },
};

type TCategories = { value: string; text: string };
export const CATEGORIES: { [key: string]: TCategories } = {
  DOCUMENTATION: {
    value: "documentation",
    text: "Documentation",
  },
  SETTINGS: {
    value: "settings",
    text: "Settings",
  },
  COLORS: {
    value: "colors",
    text: "Colors",
  },
};

interface Content {
  key: number;
  title: string;
  description: string;
  image: string;
}

interface IData {
  id: number;
  title: string;
  description: string;
  image: string;
  tags: TTags[];
  color: TColor;
  // categories: TCategories[];
  content: Content[];
}

//title是
//dsscription是副標
//image圖卡圖片
//tags是標籤
//color選擇圖卡顏色
//content[title標題、description內文、image示意圖]

const data: IData[] = [
  {
    id: 1,
    title: "自動回覆",
    description:
      "直覺、多樣化的訊息呈現、精準的觸發型標籤及觸及人數紀錄，讓行銷更便利、服務更個人化、分析更方便。",
    image: "/resources/images/jofans/solutions/auto-reply.png",
    tags: [TAGS.FACEBOOK, TAGS.LINE],
    color: COLORS.PRIMARY.name,
    // categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
    content: [
      {
        key: 1,
        title: "圖卡式介面更加直覺，簡單又輕鬆",
        description:
          "在自動回覆介面中，以圖卡式界面相連的方式呈現，讓操作更加簡單直覺，輕鬆節省時間。",
        image: "/resources/images/jofans/blog/auto-reply-1.webp",
      },
      {
        key: 2,
        title: "結合多樣化訊息，行銷更便利",
        description:
          "Jofans 串接了line以及facebook雙平台，並且line提供 文字、圖卡、快速回覆…等多達10種功能的訊息格式，創造多樣化的對話內容！",
        image: "/resources/images/jofans/blog/auto-reply-2.webp",
      },
      {
        key: 3,
        title: "觸發型標籤，紀錄顧客喜好及行為特徵",
        description:
          "以互動式對話搭配貼標籤的形式，精準區分客群，描繪受眾輪廓，提供個人化服務。",
        image: "/resources/images/jofans/blog/auto-reply-3.webp",
      },
      {
        key: 4,
        title: "觸及人數紀錄，分析更方便",
        description:
          "當使用者觸發互動對話時，Jofans就會紀錄觸及人數及次數，方便分析數據。",
        image: "/resources/images/jofans/blog/auto-reply-4.webp",
      },
    ],
  },
  {
    id: 2,
    title: "漸進式訊息",
    description:
      "提供直覺的圖卡介面、降低封鎖率、創造特殊市場氛圍、方便分析數據。",
    image: "/resources/images/jofans/solutions/progressive-message.png",
    tags: [TAGS.LINE],
    color: COLORS.SECONDARY.name,
    content: [
      {
        key: 1,
        title: "圖卡式介面更加直覺，簡單又輕鬆",
        description:
          "在自動回覆介面中，以圖卡式界面相連的方式呈現，讓操作更加簡單直覺，輕鬆節省時間。",
        image: "/resources/images/jofans/blog/progressive-message-1.webp",
      },
      {
        key: 2,
        title: "精準化推送訊息，降低封鎖率",
        description:
          "根據用戶的操作和輸入，動態更新顯示的訊息。以標籤的形式，觸發各類不同互動式對話，提供更加流暢和自然的用戶體驗。",
        image: "/resources/images/jofans/blog/progressive-message-2.webp",
      },
      {
        key: 3,
        title: "限定活動日期，創造特殊市場氛圍",
        description:
          "設定活動啟用區間，除了降低推送訊息成本外，企業可以吸引更多的消費者來購買產品或使用服務，提高銷售額和品牌知名度。",
        image: "/resources/images/jofans/blog/progressive-message-3.webp",
      },
      {
        key: 4,
        title: "觸發及發送訊息數紀錄，分析更方便",
        description:
          "當使用者觸發漸進式訊息時，Jofans就會紀錄觸發人數及訊息數，方便分析數據。",
        image: "/resources/images/jofans/blog/progressive-message-4.webp",
      },
    ],
    // categories: [CATEGORIES.DOCUMENTATION],
    // content:
    //   'Aliquam sodales tempor ullamcorper. Quisque non nibh consequat, dapibus magna et, commodo erat. Proin fringilla nibh mollis, vestibulum dui a, laoreet purus. Aliquam vehicula libero est, ut ornare quam eleifend at. Cras accumsan interdum nulla ut accumsan. Duis ornare, est vel rutrum bibendum, magna odio vehicula sem, non hendrerit dui eros ac erat. Sed et justo ac elit pellentesque ornare sit amet quis magna. Curabitur sagittis, leo pulvinar imperdiet consectetur, libero nisi rhoncus magna, non facilisis tortor mi et felis. Ut aliquet diam at eros faucibus, quis gravida nisl volutpat. Quisque eu nibh orci. Praesent posuere orci ligula, a lacinia mauris venenatis non.',
  },
  {
    id: 3,
    title: "自動推播",
    description:
      "個人化訊息分眾推送並追蹤開封數，讓行銷更加精準且方便分析數據。",
    image: "/resources/images/jofans/solutions/broadcast.png",
    tags: [TAGS.LINE],
    color: COLORS.DANGER.name,
    content: [
      {
        key: 1,
        title: "個人信息分眾，推送客製化訊息",
        description:
          "推播訊息可依照用戶標籤或是曾經參與過的遊戲中，篩選出受眾。設定開封率高峰期的時間，提升行銷成效，降低訊息成本。",
        image: "/resources/images/jofans/blog/broadcast-1.webp",
      },
      {
        key: 2,
        title: "追蹤開封數，數據分析更方便",
        description:
          "傳送推播訊息後，Jofans將會紀錄使用者查看該推播訊息的開封率，以利數據分析。",
        image: "/resources/images/jofans/blog/broadcast-2.webp",
      },
    ],
    // categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
    // content:
    //   'Pellentesque vehicula dolor a nisi tincidunt, vitae ornare enim tempus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Nunc viverra neque vel diam hendrerit volutpat. Pellentesque placerat justo a sollicitudin molestie. Etiam bibendum lacus nec tortor viverra, a consectetur neque elementum. Donec at sodales purus. Fusce in urna ac elit pulvinar efficitur in non eros. Praesent eleifend, dolor nec sollicitudin eleifend, est massa egestas metus, vitae aliquet magna erat sed turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris rutrum a augue et suscipit. In id augue ullamcorper libero tincidunt luctus.',
  },
  {
    id: 4,
    title: "即時訊息",
    description:
      "提供多元化訊息，讓客戶可立即獲得客服人員的解答，同時可根據客戶的名稱及遊戲紀錄快速分類，提供快速的客戶服務。",
    image: "/resources/images/jofans/solutions/chat-machine.png",
    tags: [TAGS.LINE],
    color: COLORS.SUCCESS.name,
    content: [
      {
        key: 1,
        title: "人機轉換不間斷，讓客戶享有誠摯體驗",
        description:
          "當客戶需要客服人員回覆時，可立即使用即時訊息。Jofans提供文字、圖片、互動對話等多樣化訊息，為客戶線上解惑，若客戶傳送的訊息中包含自動回覆的關鍵字，可以打開暫停自動回覆，免受自動回覆打擾。",
        image: "/resources/images/jofans/blog/chat-machine-1.webp",
      },
      {
        key: 2,
        title: "多元化資訊，快速提供客戶服務",
        description:
          "可篩選客戶名稱及過往所參與的遊戲紀錄，標記客戶為已完成或未完成，快速分類幫助客服人員快速為客戶提供服務。",
        image: "/resources/images/jofans/blog/chat-machine-2.webp",
      },
    ],
    // categories: [CATEGORIES.DOCUMENTATION, CATEGORIES.SETTINGS],
    // content:
    //   'Nam vitae blandit elit. Pellentesque efficitur venenatis finibus. Integer at ante rutrum, bibendum ipsum id, viverra mauris. Maecenas rhoncus ligula at lectus ullamcorper, sit amet suscipit massa tristique. Ut mattis feugiat ex, at finibus est ullamcorper in. Maecenas volutpat, odio id aliquam pulvinar, lectus velit malesuada sem, quis rutrum magna lectus quis lacus. Morbi egestas mollis nisl, quis ultrices enim iaculis vitae. Sed maximus blandit mollis. Vestibulum suscipit nibh sit amet vehicula rhoncus. Etiam gravida eu leo ac placerat. Integer vitae nunc ipsum. Phasellus maximus ullamcorper eros, nec blandit ex dignissim non. Donec vulputate molestie risus, vel hendrerit est aliquam vel.',
  },
  {
    id: 5,
    title: "里程碑",
    description:
      "可設定限定日期的活動區間，並透過獎勵及任務機制增加消費者忠誠度，創造特殊市場氛圍並促進消費。",
    image: "/resources/images/jofans/solutions/mileage-manage.png",
    tags: [TAGS.FACEBOOK, TAGS.LINE],
    color: COLORS.DARK.name,
    content: [
      {
        key: 1,
        title: "限定活動日期，創造特殊市場氛圍",
        description:
          "設定活動啟用區間，除了降低推送訊息成本外，企業可以吸引更多的消費者來購買產品或使用服務，提高銷售額和品牌知名度。",
        image: "/resources/images/jofans/blog/mileage-manage-1.webp",
      },
      {
        key: 2,
        title: "獎勵及任務機制，增加消費者忠誠度",
        description:
          "藉由設定任務並完成獲得獎勵，提高消費者對商家的忠誠度，促進消費，以及增加消費者和商家之間的互動。",
        image: "/resources/images/jofans/blog/mileage-manage-2.webp",
      },
    ],
    // categories: [CATEGORIES.SETTINGS],
    // content:
    //   'Duis posuere risus in enim sagittis, et condimentum ligula eleifend. Phasellus elementum lectus nulla. Curabitur quis vulputate ex. Nunc quis mi nibh. Vivamus sed dictum sem. Suspendisse laoreet nisl sed diam scelerisque, at gravida dui fringilla. Maecenas vel pulvinar mi. Suspendisse suscipit rhoncus dignissim. Phasellus iaculis mattis lacus, id fermentum tortor consectetur nec. Morbi bibendum neque velit, in tincidunt magna molestie vitae. Sed ultrices orci non metus pellentesque consequat. Fusce ut eleifend neque. Nunc bibendum dapibus tortor. Mauris tincidunt auctor eros sed vehicula. Maecenas a lacinia nibh. Nulla in egestas enim.',
  },
  {
    id: 6,
    title: "互動活動",
    description:
      "透過遊戲體驗促進顧客互動，設定獎項及機率增加消費者參與度，並透過O2O行銷方式增加轉換率。",
    image: "/resources/images/jofans/solutions/activity-manage.png",
    tags: [TAGS.FACEBOOK, TAGS.LINE],
    color: COLORS.WARNING.name,
    content: [
      {
        key: 1,
        title: "遊戲體驗，促進顧客互動",
        description:
          "透過抽抽樂的形式或填問卷，並設定獎項及機率，讓消費者在參與抽獎的同時，有機會獲得折扣、禮品或其他獎勵，從而促進消費和銷售。",
        image: "/resources/images/jofans/blog/activity-manage-1.webp",
      },
      {
        key: 2,
        title: "O2O行銷，增加轉換率",
        description:
          "利用線上折扣碼或到店領取獎勵方式，增加吸引消費者到實體店面領取，從而提高他們到店的頻率，在到店領取的過程中，消費者可能會進一步消費，增加店鋪的銷售額。",
        image: "/resources/images/jofans/blog/activity-manage-2.webp",
      },
    ],
    // categories: [CATEGORIES.SETTINGS],
    // content:
    //   'Maecenas id mollis turpis, non tincidunt tellus. Maecenas facilisis leo at mi accumsan tempor. Integer auctor tellus ut mi euismod, id tempus ex tempus. Curabitur feugiat arcu sem, et ultricies ligula feugiat at. Nullam nec condimentum elit, quis varius nisl. Sed venenatis at justo quis ornare. Ut sed suscipit ipsum. Aenean tempus neque eu ligula cursus convallis. Morbi ornare justo a ipsum blandit, quis varius massa euismod. Nam in orci enim. Vestibulum facilisis egestas lacus commodo vestibulum. Maecenas laoreet tincidunt dui, vel aliquam neque vestibulum vel. Pellentesque condimentum ullamcorper eros. Pellentesque bibendum convallis sem sit amet porta.',
  },
  {
    id: 7,
    title: "貼文回覆",
    description:
      "即時回覆留言和私訊內容，不漏接任何客戶訊息，同時還可以活化客戶留言區，製造火熱氣氛。",
    image: "/resources/images/jofans/solutions/post-reply.png",
    tags: [TAGS.FACEBOOK],
    color: COLORS.PRIMARY.name,
    content: [
      {
        key: 1,
        title: "即時回覆留言及私訊內容，訊息不漏接",
        description:
          "當客戶在指定貼文下新增留言或是私訊紛絲團時，Jofans將會自動回覆該對應內容，以達到客戶在最短時間內就能收到想推廣的資訊。",
        image: "/resources/images/jofans/blog/post-reply-1.webp",
      },
      {
        key: 2,
        title: "活化客戶留言區，製造火熱氣氛",
        description:
          "貼文舉辦活動時，可以判斷標記人數及私訊人數是否符合參加資格，並且可以設定自動對留言按讚。",
        image: "/resources/images/jofans/blog/post-reply-2.webp",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
  {
    id: 8,
    title: "圖文選單",
    description:
      "可分眾顯示，製作專屬選單，並設定活動啟用區間，創造特殊市場氛圍，提高銷售額和品牌知名度。",
    image: "/resources/images/jofans/solutions/rich-menu.png",
    tags: [TAGS.LINE],
    color: COLORS.INFO.name,
    content: [
      {
        key: 1,
        title: "分眾顯示，製作專屬選單",
        description:
          "針對未綁定jofans會員或是綁定jofans的會員顯示不同的圖文選單，區分受眾，製造專屬體驗。",
        image: "/resources/images/jofans/blog/rich-menu-1.webp",
      },
      {
        key: 2,
        title: "限定活動日期，創造特殊市場氛圍 ",
        description:
          "設定活動啟用區間，除了降低推送訊息成本外，企業可以吸引更多的消費者來購買產品或使用服務，提高銷售額和品牌知名度。",
        image: "/resources/images/jofans/blog/rich-menu-2.webp",
      },
    ],
    // categories: [CATEGORIES.COLORS],
    // content:
    //   'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi rhoncus, turpis mollis tincidunt feugiat, augue enim dapibus ipsum, et placerat neque nibh sit amet justo. Praesent venenatis ex eu massa aliquam congue eu sed diam. Vestibulum suscipit lacus et justo ornare, at rutrum erat malesuada. Fusce ut rutrum dui. Donec posuere fringilla urna, ut efficitur mi feugiat et. In ut elit at turpis dapibus pretium quis vel turpis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam rhoncus vel erat a finibus. Nulla facilisi. Suspendisse ornare rhoncus sollicitudin. Curabitur mollis, erat id tincidunt efficitur, arcu sem elementum enim, ac lacinia tortor purus vel ante. Nullam non feugiat magna.',
  },
];

export default data;
