import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import data from "./helper/dummyKnowledgeData";
import SubHeader, {
  SubHeaderLeft,
  SubHeaderRight,
  SubheaderSeparator,
} from "../../../layout/SubHeader/SubHeader";
import Button from "../../../components/bootstrap/Button";
import Avatar from "../../../components/Avatar";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Badge from "../../../components/bootstrap/Badge";
import USERS from "../../../common/data/userDummyData";
import useDarkMode from "../../../hooks/useDarkMode";
import useTourStep from "../../../hooks/useTourStep";
import Card, { CardBody } from "../../../components/bootstrap/Card";

import Lightbox from "react-18-image-lightbox";
import "./style.scss";

const KnowledgeViewPage = () => {
  useTourStep(16);
  const { darkModeStatus } = useDarkMode();

  const { id } = useParams();
  const navigate = useNavigate();
  const itemData = data.filter((item) => item.id.toString() === id?.toString());
  const item = itemData[0];

  const [viewImageUrl, setViewImageUrl] = useState("");

  const [viewImageAlt, setViewImageAlt] = useState("");
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  // 新手教學的文章框架

  return (
    <PageWrapper title={item.title}>
      <SubHeader>
        <SubHeaderLeft>
          <Button
            color="info"
            isLink
            icon="ArrowBack"
            onClick={() => navigate(-1)}
          >
            回到列表
          </Button>
          <SubheaderSeparator />
          {!!item.tags &&
            // eslint-disable-next-line react/prop-types
            item.tags.map((tag) => (
              <div key={tag.text} className="col-auto">
                <Badge isLight color={tag.color} className="px-3 py-2">
                  {tag.text}
                </Badge>
              </div>
            ))}
        </SubHeaderLeft>
        <SubHeaderRight>
          <span className="text-muted fst-italic">小編</span>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Avatar {...USERS.GRACE} size={32} />
          <span>
            <strong>徐先生</strong>
          </span>
        </SubHeaderRight>
      </SubHeader>

      <Page>
        <div className="display-4 fw-bold pt-3 pb-5">{item.title}</div>
        <div className="row g-4">
          {/* <div className="col-12">
						<div
							className={classNames(
								'ratio ratio-21x9',
								'rounded-2',
								`bg-l${darkModeStatus ? 'o25' : '10'}-${item.color}`,
								'mb-3'
							)}
						>
							<img
								src={item.image}
								alt={item.title}
								width="100%"
								height="auto"
								className="object-fit-contain p-5"
							/>
						</div>
					</div> */}
          <div className="col-12">
            <h3 className="text-muted ">{item.description}</h3>
          </div>

          {item.content.map((content, index) => (
            <div className="col-12 col-xl-12 col-md-12" key={index}>
              

              <div className="col-12">
                <h2
                  className="text-muted"
                  style={{
                    fontWeight: "700",
                    fontFamily: "SegoeUISymbolRegular",
                  }}
                >
                  {content.title}
                </h2>
              </div>

              {content.description && (
                <div
                  className="col-12 p-2"
                  style={{
                    fontSize: "18px",
                    fontFamily: "SegoeUISymbolRegular",
                  }}
                >
                  {content.description || null}
                </div>
              )}
              <div
                className={classNames(
                  // 'ratio ratio-21x9',
                  "rounded-4",
                  // `bg-l${darkModeStatus ? "o25" : "10"}-${item.color}`,
                  "mb-3"
                )}
              >
                {content.image && (
                  <Card className=" bg-light ">
                    <CardBody color="brand">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setViewImageUrl(content.image);
                          setViewImageAlt(content.title);
                          setTimeout(() => {
                            setIsLightboxOpen(true);
                          }, 100);
                        }}
                      >
                        <img
                          src={content.image}
                          alt={content.title}
                          width="100%"
                          height="auto"
                          className="rounded-4"
                        />
                      </div>
                    </CardBody>
                  </Card>
                )}
              </div>
            </div>
            
          ))}
          
        </div>
        
        {isLightboxOpen && (
          <Lightbox
            mainSrc={viewImageUrl}
            onCloseRequest={() => setIsLightboxOpen(false)}
            imageTitle={viewImageAlt}
          />
        )}
      </Page>
    </PageWrapper>
  );
};

export default KnowledgeViewPage;
