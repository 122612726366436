import React, { SVGProps } from 'react';

const SvgCustomTaiwan = (props: SVGProps<SVGSVGElement>) => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" xmlSpace="preserve"
     width='1em'
     height='1em'
     {...props}
     >
<path style={{fill:"#FF4B55"}} d="M473.655,423.724H38.345C17.167,423.724,0,406.557,0,385.38V126.622
	c0-21.177,17.167-38.345,38.345-38.345h435.31c21.177,0,38.345,17.167,38.345,38.345V385.38
	C512,406.557,494.833,423.724,473.655,423.724z"/>
<path style={{fill:"#41479B"}} d="M8.828,256h238.345c4.875,0,8.828-3.951,8.828-8.828V97.104c0-4.875-3.953-8.828-8.828-8.828H38.345
	C17.167,88.277,0,105.444,0,126.622v120.552C0,252.049,3.953,256,8.828,256z"/>
<g>
	<path style={{fill:"#F5F5F5"}} d="M96.403,172.139c0-8.722,3.534-16.618,9.248-22.335l-26.474-7.573
		c-1.537-0.439-2.638,1.469-1.49,2.58l19.811,19.155l-26.734,6.684c-1.55,0.387-1.55,2.591,0,2.979l26.734,6.684l-19.811,19.155
		c-1.149,1.111-0.047,3.019,1.49,2.58l26.474-7.573C99.937,188.757,96.403,180.861,96.403,172.139z"/>
	<path style={{fill:"#F5F5F5"}} d="M105.665,149.79c5.717-5.714,13.613-9.248,22.335-9.248s16.618,3.534,22.335,9.248l7.573-26.474
		c0.439-1.537-1.469-2.638-2.58-1.49l-19.155,19.81l-6.684-26.734c-0.387-1.55-2.591-1.55-2.979,0l-6.684,26.734l-19.155-19.81
		c-1.111-1.149-3.02-0.047-2.58,1.49L105.665,149.79z"/>
	<path style={{fill:"#F5F5F5"}} d="M150.335,194.488c-5.717,5.714-13.613,9.248-22.335,9.248s-16.618-3.534-22.335-9.248l-7.573,26.475
		c-0.439,1.537,1.469,2.638,2.58,1.49l19.155-19.81l6.684,26.734c0.387,1.55,2.591,1.55,2.979,0l6.684-26.734l19.155,19.81
		c1.111,1.149,3.019,0.047,2.58-1.49L150.335,194.488z"/>
	<path style={{fill:"#F5F5F5"}} d="M185.236,170.648l-26.734-6.684l19.81-19.155c1.149-1.111,0.047-3.019-1.49-2.58l-26.474,7.573
		c5.714,5.718,9.248,13.613,9.248,22.335s-3.534,16.618-9.248,22.335l26.474,7.573c1.537,0.439,2.638-1.469,1.49-2.58l-19.81-19.155
		l26.734-6.684C186.787,173.241,186.787,171.038,185.236,170.648z"/>
	<circle style={{fill:"#F5F5F5"}} cx="128" cy="172.141" r="25.16"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>

)

export default SvgCustomTaiwan;