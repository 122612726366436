/* eslint-disable @typescript-eslint/default-param-last */
type ActionProp = {
  type: string;
  payload?: {
    title: string;
    content: string;
    func: void;
  };
};

const initialState = {
  show: false,
  title: '',
  content: '',
  func: () => {},
};

export default function confirmReducer(
  state = initialState,
  action: ActionProp
) {
  switch (action.type) {
    case 'CONFIRM_SHOW': {
      console.log('show');
      return { ...action.payload, show: true };
    }
    case 'CONFIRM_HIDE': {
      return { ...initialState };
    }
    case 'CONFIRM_ONCLICK': {
      state.func();
      return { ...initialState };
    }
    default: {
      return state;
    }
  }
}
