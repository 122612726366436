import React, { useContext } from "react";
import Header, { HeaderLeft, HeaderRight } from "../../../layout/Header/Header";
import Button, { IButtonProps } from "../../../components/bootstrap/Button";
import useDarkMode from "../../../hooks/useDarkMode";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "react-use";
import ThemeContext from "../../../contexts/themeContext";

const JofansHeader = () => {
  const { darkModeStatus, setDarkModeStatus } = useDarkMode();
  const navigate = useNavigate();

  const styledBtn: IButtonProps = {
    color: "brand",
    hoverShadow: "default",
    isLight: !darkModeStatus,
    size: "lg",
  };
  const windowsWidth = useWindowSize().width;

  const {
    setAsideStatus,
    setLeftMenuStatus,
    rightMenuStatus,
    setRightMenuStatus,
  } = useContext(ThemeContext);

  return (
    <Header type="jofans">
      <HeaderLeft>
        {windowsWidth >= 760 ? (
          <Button
            to="/jofans"
            onClick={() => navigate("/jofans")}
            hoverShadow="none"
            isLink
          >
            <img
              height={"48px"}
              width={"100%"}
              src="/resources/images/logo/jofans_banner.svg"
              alt="logo"
              onClick={() => navigate("/jofans")}
              style={{ cursor: "pointer" }}
            />
          </Button>
        ) : null}
      </HeaderLeft>

      <HeaderRight>
        <div className="container">
          <div className="row g-3">
            <div className="col">
              <Button
                {...styledBtn}
                className="w-100"
                to={"/jofans/solutions"}
                data-tour="dark-mode"
                onClick={() => {
                  setRightMenuStatus(!rightMenuStatus);
                  navigate("/jofans/solutions");
                }}
              >
                解決方案
              </Button>
            </div>
            <div className="col">
              <Button
                {...styledBtn}
                className="w-100"
                // to={'/index'}
                data-tour="dark-mode"
                onClick={() =>
                  (window.location.href = "https://www.jofans.com.tw/")
                }
              >
                立即開始
              </Button>
            </div>
          </div>
        </div>
      </HeaderRight>
    </Header>
  );
};

export default JofansHeader;
