import React, { useState } from "react";
import { useMeasure } from "react-use";
import classNames from "classnames";
import useDarkMode from "../../hooks/useDarkMode";
import { useNavigate } from "react-router-dom";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../components/bootstrap/Modal";
import Button from "../../components/bootstrap/Button";
import Icon from "../../components/icon/Icon";

const Footer = () => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const navigate = useNavigate();
  const [state, setState] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenModal2, setIsOpenModal2] = useState<boolean>(false);

  const root = document.documentElement;
  root.style.setProperty("--footer-height", `${height}px`);

  const { darkModeStatus } = useDarkMode();

  return (
    <footer ref={ref} className="footer">
      <div className="container-fluid ">
        <div className="row">
          <div className="col">
            <span className="fw-light">
              <small className="fw-bold">根垣科技有限公司｜</small>

              <small className="fw-bold">統一編號：91094694｜</small>

              <small className="fw-bold">
                聯絡方式：
                <a
                  href="mailto:service@ramatetech.com"
                  className="text-info"
                  target="_blank"
                >
                  service@ramatetech.com
                </a>
              </small>
            </span>
          </div>
          <div className="col-auto">
            <a
              style={{ cursor: "pointer" }}
              onClick={() => setIsOpenModal(true)}
            >
              <small className="">使用者條款</small>｜
            </a>
            <a
              style={{ cursor: "pointer" }}
              onClick={() => setIsOpenModal2(true)}
            >
              <small className="">隱私權政策</small>
            </a>
          </div>
          <Modal
            isOpen={isOpenModal}
            setIsOpen={setIsOpenModal}
            titleId="tour-title"
            size="xl"
          >
            <ModalHeader setIsOpen={setIsOpenModal}>
              <ModalTitle id="tour-title" className="d-flex align-items-end">
                <span className="ps-2">使用者條款</span>
                <span className="ps-2">
                  <Icon icon="Verified" color="info" />
                </span>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div className="row d-flex justify-content-center">
                <div className="col-md-10">
                  <div>
                    <h2 className="text-center font-weight-bold">使用者條款</h2>
                    <code className="d-flex align-items-right">
                      2021.07.08版
                    </code>
                    <p className="lead">
                      非常歡迎您使用根垣科技有限公司（以下簡稱「本公司」）的「Bombmy幫賣」平台（以下簡稱「本網站」），為了讓您能夠安心使用本網站的各項服務與資訊（以下簡稱「本服務」），特此向您說明本網站的使用者條款（以下簡稱「本條款」），以保障您的權益。您於使用本服務前，應詳細閱讀本條款所有內容，具體條款如下：
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        1、認知及同意條款
                      </span>
                      <br />
                      <ol type="1">
                        <li>
                          當您使用本服務時，即表示您已閱讀、了解並同意本條款所有內容，並完全接受本服務現有與未來衍生的服務項目、內容及所有規範。您不同意本條款之內容時，請停止使用本服務。
                        </li>
                        <li>
                          如您為未滿十八歲之未成年人或無完全行為能力人，請事前徵得家長（監護人、法定代理人）陪同閱讀、瞭解並同意本條款之所有內容及其後修改變更後，再註冊為會員並使用本服務。當未成年或無完全行為能力使用者使用或繼續使用本服務，視為其家長（監護人、法定代理人）已閱讀、瞭解並同意本條款之所有內容及其後修改變更之內容。
                        </li>
                        <li>
                          您同意您的意思表示，得以包含但不限於書面或電子文件作為表示方式。依本條款交換之電子文件，其效力與書面文件相同。
                        </li>
                      </ol>
                      <span style={{ fontWeight: "bold" }}>2、條款更新</span>
                      <br />
                      您同意本網站有權於任何時間修改或變更本條款及隱私權政策內容，為確保權益完整，您應定期或不定期檢視本條款及隱私權政策。建議您隨時注意該修改或變更。當您於任何修改或變更後繼續使用本服務，視為您已閱讀、了解並同意接受該修改或變更內容。如果您不同意本條款或隱私權政策進行的修改，請離開本網站並立即停止使用本服務。同時，您應刪除個人檔案並註銷會員資格，本網站亦有權刪除會員個人檔案並註銷會員資格。
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        3、會員帳號及安全
                      </span>
                      <ol type="1">
                        <li>
                          您如欲使用本網站之完整服務時，需依本網站指定之方法註冊成為會員（以下簡稱「會員」或「您」）。
                        </li>
                        <li>
                          會員註冊時，及註冊期間所提供之資料，應為會員本人真實、正確、最新及完整的資料。會員註冊資料（如：個人資料及信用卡資料）不得有偽造、不實、冒用等之情事，一經發現，本網站可拒絕其加入會員資格之權利，並得以暫停或終止其會員資格，若違反中華民國相關法律，亦將依法追究。
                        </li>
                        <li>
                          若會員提供任何錯誤或不實之資料，除自行負擔因此而造成的損失外，本網站有權不經事先通知，逕行暫停或終止會員帳號，並拒絕會員使用本服務之全部或一部份，且會員不得異議。本網站將以註冊資料核對會員資格、提供本服務，並通知相關會員權益資訊，以及依據隱私權政策維護會員提供本網站之個人資料。
                        </li>
                        <li>
                          會員應妥善保管密碼，不可將密碼洩露或提供給他人知道或使用；以同一個會員帳號和密碼使用本服務所進行的所有行為，都將被認為是會員本人的行為。
                        </li>
                        <li>
                          若會員為未滿七歲者，須由您的父母或法定監護人申請加入會員。若您已滿七歲未滿十八歲，則必須在申請加入會員之前，請父母或法定監護人閱讀本條款，並得到父母或監護人同意後，才可以申請、註冊及使用本網站所提供的服務；當您滿十八歲之前使用本服務，則必須向本網站擔保已取得父母或監護人的同意。
                        </li>
                      </ol>
                      <span style={{ fontWeight: "bold" }}>4、服務說明</span>
                      <ol type="1">
                        <li>
                          您理解並同意，本服務中包含付費服務與免付費服務，您可以依據各服務方案及利用本網站所提供之付費方式，使用本網站所提供之付費服務（以下簡稱「
                          <marker className="heightlight">訂購服務</marker>
                          」）。本服務若為需要付費方能使用之服務，本網站會以顯著標註方式向您提示。
                        </li>
                        <li>
                          您瞭解並同意於訂購前，已透過相關頁面中之說明，瞭解各訂購方案下本網站所提供之功能、服務內容種類及費用。
                        </li>
                        <li>
                          本公司如有贈送會員相關之優惠權益，亦有使用期限，會員應於期間將優惠權益使用完畢，逾期未使用則視同放棄優惠權益，本公司恕無法將未使用完畢之優惠權益累計或展延，亦不額外提供補償。
                        </li>
                        <li>
                          您同意本公司可根據業務開展或技術升級等情況，變更或修改訂購服務之相關內容或功能等項目。本公司在做出上述改變或修改前，將在本網站相關頁面上事先公告修改的內容與生效日。若您於生效日之後繼續使用訂購服務，則本公司將自動視為您已同意本公司訂購服務之修改或變更，本公司將以修改或變更後之訂購服務內容對您繼續提供服務。
                        </li>
                      </ol>
                      <span style={{ fontWeight: "bold" }}>
                        5、付費方式與注意事項
                      </span>
                      <ol type="1">
                        <li>
                          您可利用下列方式付費訂購服務：
                          <br />
                          信用卡付費：信用卡付費訂購服務後，原則以當月出帳並於次月扣款，惟實際依各付費平台公司之規定決定。
                        </li>
                        <li>
                          若因過期、資金不足或其他原因導致付費失敗，則本公司可暫停您對服務的使用，直到本公司成功透過有效「付費方式」收費為止。
                        </li>
                        <li>
                          針對部分付費方式，發行機構可能會向您收取特定費用，例如跨國交易手續費或其他針對您的付費方式之處理所收取的費用。當地稅額可能因所使用的付費方式而有所不同。詳情請諮詢您的付費方式服務提供者。
                        </li>
                        <li>
                          您瞭解並同意本網站之服務屬於「通訊交易解除權合理例外情事適用準則」第2條第5款「一經提供即為完成之線上服務」，不適用消費者保護法第19條猶豫期間解除權之規定。請於付費前，評估是否符合您的需求。
                        </li>
                      </ol>
                      <span style={{ fontWeight: "bold" }}>6、智慧財產權</span>
                      <ol type="1">
                        <li>
                          您使用本網站所提供之功能服務時，如有上傳至本網站之影片、歌曲等行為，您同意授權本網站得利用您所上傳之內容及相關權利，行使服務所需之包括但不限於重製及改作等權利，您同意不對本公司行使相關權利（包括但不限於著作人格權等）。
                        </li>
                        <li>
                          您同意本服務所使用之功能、軟體、圖片、程式及網站上所有內容，包括但不限於影片、文字、圖片、檔案、資訊（料）、網站或APP畫面安排、設計及內容均由本網站或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。除經本網站及權利人授權或同意外，您不得擅自為著作權法、商標法及其他法規所規定之各式利用行為，亦不得擅自複製、進行還原工程（reverse
                          engineering）、解編（de-compile）或反向組譯（disassemble）任何功能或程式。如有違反，本網站將依相關法律規定論處，您並應對本網站負損害賠償責任（包括但不限於訴訟費用及律師費用等），本網站並得撤銷您會員之資格、禁止使用本服務，亦得永久拒絕您之會員申請。
                        </li>
                        <li>
                          除取得本網站單次授權或永久授權外，您使用本服務而獲本網站授權使用內容之一部或全部閱覽權，但並不因此而取得本網站內容之智慧財產權和其他法律權利。
                        </li>
                        <li>
                          如果本服務需有或內含可下載之素材或特效等功能，該功能可能會在提供新版時，在您的裝置上自動更新。部分情況下可以讓您調整您的自動更新設定。
                        </li>
                      </ol>
                      <span style={{ fontWeight: "bold" }}>
                        7、使用者／會員責任
                      </span>
                      <ol type="1">
                        <li>
                          您使用本網站所提供之服務時，應遵守相關法律，並同意不得利用本服務從事侵害本網站或他人權利或違法行為，此行為包含但不限於：
                          <br />
                          <ol type="a">
                            <li>
                              上載、張貼、公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案。
                            </li>
                            <li>
                              侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。
                            </li>
                            <li>違反法律、合約或協議所應負之保密義務。</li>
                            <li>
                              為任何非法目的或以任何非法、不當方式使用本網站及本服務（包含但不限於使用惡意爬蟲程式或進行還原工程解編或反向組繹）。
                            </li>
                            <li>
                              企圖入侵本網站伺服器、資料庫、破解本網站安全機制或其他危害本服務提供安全性或穩定性之行為。
                            </li>
                            <li>
                              從事不法交易行為或張貼虛假不實、引人犯罪之訊息。
                            </li>
                            <li>
                              未經本網站明確授權同意並具書面授權同意證明，不得利用本服務或本網站所提供其他資源，包括但不限於圖文、影音資料庫、編寫製作網頁之軟體等，從事任何商業交易行為，或招攬廣告商或贊助人。
                            </li>
                            <li>販賣槍枝、毒品、盜版軟體或其他違禁物。</li>
                            <li>提供賭博資訊或以任何方式引誘他人參與賭博。</li>
                            <li>
                              濫發廣告訊息、垃圾訊息、連鎖信、違法之多層次傳銷訊息等。
                            </li>
                            <li>其他本網站有正當理由認為不適當之行為。</li>
                          </ol>
                        </li>
                        <li>
                          您應對於使用本服務所從事的所有行為及其結果自行負擔一切責任。
                        </li>
                        <li>
                          若您發生或可能發生下列情事，本網站不須事先告知，即可終止您使用本服務之全部或一部、停用或刪除本網站帳戶、取消會員與本網站之間關於本服務的合約（包括但不限於依據本條款成立的合約，以下皆同），或採取本網站合理認為必要及適當的任何其他措施：
                          <ol type="a">
                            <li>違反相關法令、或本使用條款；</li>
                            <li>
                              透過散播不實資訊，例如利用詐欺方式或勢力，或透過其他不法方破壞本網站或本公司的信譽；
                            </li>
                            <li>
                              遭聲請被扣押、假扣押、拍賣、進入破產、民事重整或類似程序，或本網站合理認為您的信用有不確定性；或
                            </li>
                            <li>
                              與本網站或本公司之間的信任關係已不存在，或因上列第1款至第4款以外事由，使本公司合理認為本網站不再適合向您提供本服務。
                            </li>
                          </ol>
                        </li>
                      </ol>
                      <span style={{ fontWeight: "bold" }}>
                        8、第三方資源連結及下載
                      </span>
                      <br />
                      本網站可能含有連結至本網站以外之第三方網站、或使用第三方應用程式、軟體（以下合稱「第三方資源」），由於本網站無法控制這些第三方資源，因此您於使用各該第三方資源前，需自行瞭解各該第三方資源之使用者條款及隱私權政策。本網站也不對第三方資源及裝置與服務的相容性或持續相容性提供任何保證。
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        9、免責聲明與責任限制
                      </span>
                      <ol type="1">
                        <li>
                          有下列情事之一者，本網站有權暫停、變更或終止本服務之全部或一部，而對於會員因此所致損害，本網站亦不負任何賠償或補償之責：
                          <ol type="a">
                            <li>本網站之免付費服務；</li>
                            <li>
                              本服務相關軟硬體設備進行搬遷、更換、升級、保養或維修者；
                            </li>
                            <li>會員有任何違反政府法令或本服務條款者；</li>
                            <li>天災或其他不可抗力事由；</li>
                            <li>
                              非本網站所得控制之事由致本服務資訊顯示不正確、遭偽造、竄改、刪除或擷取或致系統中斷或不能正常運作時；
                            </li>
                            <li>其他不可歸責於本網站之事由。</li>
                          </ol>
                        </li>
                        <li>
                          本網站將依一般合理之技術與方式，維持本網站及本服務之正常運作，然有以下各項情事者，本網站有權自行停止、中斷之，並不負任何賠償或補償責任：
                          <ol type="a">
                            <li>
                              本網站或本服務所用之電子通信設備進行必要之保養或施工時；
                            </li>
                            <li>前款電子設備服務被停止，無法提供服務時；</li>
                            <li>發生突發性之電子通信設備故障時；</li>
                            <li>
                              因不可抗力因素或其他不可歸責本網站之事由，致本網站無法提供服務時。
                            </li>
                          </ol>
                        </li>
                        <li>
                          本網站對本服務不提供任何明示或默示之擔保，包括但不限於以下事項：
                          <ol type="a">
                            <li>
                              本服務不受干擾、即時更新、安全可靠或免於錯誤；
                            </li>
                            <li>本服務使用而取得之結果為正確或可靠；</li>
                            <li>
                              經由本服務取得之任何商品、服務、資訊或其他資料將符合會員的需求或期望；
                            </li>
                            <li>
                              使用本服務或取得任何資料應由會員自行評估並自行負擔風險，如因前述任何資料之下載而致會員於本網站之任何損失或資料流失，均由會員自負完全責任。
                            </li>
                          </ol>
                        </li>
                        <li>
                          本網站不因不可抗力事由所致之給付遲延、或給付不能負擔違約責任。
                        </li>
                      </ol>
                      <span style={{ fontWeight: "bold" }}>10、終止服務</span>
                      <br />
                      <ol type="1">
                        <li>
                          您使用本服務之行為若有任何違反法令、本條款、危害本網站或第三者權益之虞時，在法律准許範圍內，於通知或未通知之情形下，立即暫時或永久終止您使用本服務之授權。此外，若本服務之使用被中斷或終止帳號及相關信息和文件被關閉或刪除，本網站對您或任何第三人均不承擔任何責任。
                        </li>
                        <li>
                          本網站終止本服務後，不論是否已通知，均得刪除您會員之帳號及內容。
                        </li>
                      </ol>
                      <span style={{ fontWeight: "bold" }}>11、賠償</span>
                      <br />
                      <ol type="1">
                        <li>
                          您擔保如本網站及其管理階層、員工、代理人及承包商因您所為以下事項，而產生之第三人或本公司各樣主張、要求，致本公司受有損失、須負責任、損害賠償或支出（包括但不限於合理的律師費用）時，您同意負賠償責任：
                          <ol type="a">
                            <li>您所發布或遞交之內容；</li>
                            <li>您違反本條款；</li>
                            <li>您侵犯第三人之任何權利。</li>
                          </ol>
                        </li>
                        <li>
                          起因於您使用本服務（包括但不限於本網站遭第三人對於您使用本服務提出損害賠償請求），致本網站直接或間接蒙受任何損失/損害（包括但不限於律師費用的負擔）時，您應依照本網站的要求立即給予補償賠償。
                        </li>
                        <li>
                          如本網站因可歸責事由或理由而被認定需對您負擔損害賠償責任，本網站就該事件的損害賠償額度上限為您所支付的訂購費用。
                        </li>
                      </ol>
                      <span style={{ fontWeight: "bold" }}>12、聯繫我們</span>
                      <br />
                      如對本條款或本網站有任何疑問，請通過電子郵件（信箱：
                      <a href="mailto:service@ramatetech.com" target="_blank">
                        service@ramatetech.com
                      </a>
                      ）與我們聯繫。
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        13、準據法與管轄法院
                      </span>
                      <br />
                      <ol type="1">
                        <li>
                          本條款之解釋、補充及適用均以中華民國相關法令為準據法。本條款中任何條款之全部或一部份無效時，不影響其他條款之效力。
                        </li>
                        <li>
                          因本條款所生之爭議，雙方同意以臺灣台中地方法院為第一審管轄法院。
                        </li>
                      </ol>
                      <span style={{ fontWeight: "bold" }}>14、退款服務</span>
                      <br />
                      虛擬商品不屬於實體物品，為數位內容，因此根據
                      <a
                        href="https://law.moj.gov.tw/LawClass/LawAll.aspx?media=print&pcode=J0170012"
                        target="_blank"
                      >
                        《消費者保護法》第十九條
                      </a>
                      中規定「非以有形媒介提供之數位內容或一經提供即為完成之線上服務，經消費者事先同意始提供。」因此無提供七天鑑賞期服務，商品無法進行退款、退貨與換貨。
                      如對本條款或本網站有任何疑問，請通過電子郵件（信箱：
                      <a href="mailto:service@ramatetech.com" target="_blank">
                        service@ramatetech.com
                      </a>
                      ）與我們聯繫。
                    </p>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={isOpenModal2}
            setIsOpen={setIsOpenModal2}
            titleId="tour-title"
            size="xl"
          >
            <ModalHeader setIsOpen={setIsOpenModal2}>
              <ModalTitle id="tour-title" className="d-flex align-items-end">
                <span className="ps-2">隱私權政策</span>
                <span className="ps-2">
                  <Icon icon="Verified" color="info" />
                </span>
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div className="row d-flex justify-content-center">
                <div className="col-md-10">
                  <div>
                    <h2 className="text-center font-weight-bold">隱私權政策</h2>
                    <code className="d-flex align-items-right">2023.01版</code>
                    <p className="lead">
                      感謝您使用「Bombmy幫賣」（以下簡稱「本平台」），為了讓您能夠安心的使用本公司（根垣科技有限公司）所提供本平台的各項服務與資訊，並符合個人資料保護法等要求，特此向您說明本平台的隱私權政策，以保障您的權益，因此，請您使用本平台提供之服務前詳閱本隱私權政策。當您註冊、開始使用本平台服務時，視為您已閱讀、理解並同意本隱私權政策。
                      <br />
                      為保護您的隱私及提供更完整服務，本公司得適時修正本隱私權政策，並於公布更新於本平台後即時生效，建議您適時查閱本隱私權政策。您於任何修改或變更後繼續使用本平台，即視為您已閱讀、瞭解並接受該等修改或變更。如您不同意本隱私權政策，請不要使用相關服務。
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        1、隱私權政策的適用範圍
                      </span>
                      <br />
                      本隱私權政策之條款內容，包括本平台如何處理在您使用網站服務時蒐集到的個人資料。本隱私權政策不適用於本平台任何以外的相關連結網站及內容，也不適用於非本平台所委託或參與管理的人員。
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        2、個人資料的蒐集、處理及利用方式
                      </span>
                      <ol type="1">
                        <li>
                          當您造訪本平台、或使用本平台服務時，本公司將視該服務功能性質，於必要的範圍內向您蒐集必要相關的個人資料，包括但不限於電子郵件地址、姓名、聯絡方式、持久性標識（如會員註冊名稱與密碼）、交易所需財務資料、使用時間等，請您提供必要的個人資料，以利本公司向您提供本平台數位服務或互動功能（如：使用服務信箱）、聯繫、交易服務、問卷調查或廣告與行銷目的等特定目的服務，而本公司將於前述特定目的範圍內，於特定目的存續期間，及所需區域內由本公司及協力廠商（包括代理商）以電子等合法合理方式處理及利用您的個人資料。如您選擇不提供或未完整提供個人資料時，將無法使用本平台提供之部分或全部服務，並可能影響您的權益。非經您書面同意，本平台不會將個人資料用於其他用途。
                        </li>
                        <li>
                          於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料紀錄（包含Cookies）等，做為本公司增進平台服務的參考依據，此紀錄為內部應用。為提供您精確及優化的服務，本公司會將蒐集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，本公司會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。
                        </li>
                        <li>
                          您同意本公司可以使用您註冊會員之電子信箱或其他登入資料，發送本公司之公告或商業訊息。
                        </li>
                        <li>
                          使用本平台服務中，如您上傳內容包含第三人之個人資料時，您需擔保已獲該第三人同意且本平台得使用之，如生爭議，均由您負責。
                        </li>
                      </ol>
                      <span style={{ fontWeight: "bold" }}>3、資料之保護</span>
                      <br />
                      本平台已設置相關資訊安全設備及必要且適當的安全防護措施，加以保護本平台及您的個人資料。對於您提供之個人資料將僅用於本公司內部進行平台或個人化服務之設計與優化，除法律另有規定外，本公司不會向任何人透漏這些資料。
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        4、網站對外的相關連結
                      </span>
                      <br />
                      當您自本平台連結至其它第三方網站進行活動前，請您詳閱該第三方網站之隱私保護相關約款或聲明，本平台不負擔任何連帶責任。
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        5、與第三人共用個人資料之政策
                      </span>
                      <br />
                      <ol type="1">
                        <li>
                          本平台不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但以下情況不在此限：
                          <br />
                          <ol type="a">
                            <li>經由您同意。</li>
                            <li>與本公司有契約或類似契約關係者。</li>
                            <li>法律明文規定或有權機關基於法定程序之要求。</li>
                            <li>為免除您生命、身體、自由或財產上之危險。</li>
                            <li>
                              與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集者依其揭露方式無從識別特定之當事人。
                            </li>
                            <li>
                              當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
                            </li>
                            <li>有利於您的權益時。</li>
                            <li>
                              因合併、轉讓或其他事由而繼受本平台及其提供之服務，包含提供使用者個人資料在內時。
                            </li>
                            <li>
                              受託或協力廠商協助本平台服務，而該服務涉及蒐集、處理或利用您的個人資料時。
                            </li>
                          </ol>
                        </li>
                        <li>
                          本平台如委託廠商協助蒐集、處理或利用您的個人資料時，受託或協力廠商依前述服務之目的範圍以書面、電子、電話、簡訊等合理合法方式運用您的個人資料。
                        </li>
                      </ol>
                      <span style={{ fontWeight: "bold" }}>
                        6、Cookie之使用
                      </span>
                      <br />
                      為了提供您最佳的服務，本平台會在您的電腦中放置並取用本公司的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導致本平台某些功能無法正常執行。
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        7、個人資料之正確性及不提供資料時對於權益之影響
                      </span>
                      <br />
                      您有義務提供並隨時更新您的個人資料，若您拒絕提供、未提供完整個人資料或提供之個人資料有錯誤時，您將可能無法再使用本平台所提供之部分或全部服務、或影響您的權益，您並了解且願意承擔因此所受相關損害。
                      <br />
                      <span style={{ fontWeight: "bold" }}>8、使用者權利</span>
                      <br />
                      除法令另有規定外，您享有查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集處理或利用、請求刪除個人資料的權利。請留意，如您選擇刪除相關個人資料後，將可能無法再使用本平台所提供之部分或全部服務。
                      如您欲行使前述之權利，或不希望收到任何活動、行銷推廣資訊之通知，可隨時透過本平台所載之聯絡資訊，檢附相關資訊，聯繫本平台，以提供相關協助。
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        9、隱私權政策之修正
                      </span>
                      <br />
                      本平台隱私權政策之條款將因應法律要求或業務需求隨時進行修正，修正後的條款將刊登於網站上，即生效力。您應隨時查閱本平台最新版的隱私權政策。如您繼續使用本平台服務，將視為您已同意本平台隱私權政策之修改。
                      <br />
                      <span style={{ fontWeight: "bold" }}>10、聯繫我們</span>
                      <br />
                      如您對本隱私權政策或本平台之個人資料使用方法有任何疑問，請通過電子郵件（信箱：
                      <a href="mailto:service@ramatetech.com" target="_blank">
                        service@ramatetech.com
                      </a>
                      ）與本公司聯繫。
                      <br />
                      <span style={{ fontWeight: "bold" }}>11、退款服務</span>
                      <br />
                      虛擬商品不屬於實體物品，為數位內容，因此根據
                      <a
                        href="https://law.moj.gov.tw/LawClass/LawAll.aspx?media=print&pcode=J0170012"
                        target="_blank"
                      >
                        《消費者保護法》第十九條
                      </a>
                      中規定「非以有形媒介提供之數位內容或一經提供即為完成之線上服務，經消費者事先同意始提供。」因此無提供七天鑑賞期服務，商品無法進行退款、退貨與換貨。
                      如對本條款或本網站有任何疑問，請通過電子郵件（信箱：
                      <a href="mailto:service@ramatetech.com" target="_blank">
                        service@ramatetech.com
                      </a>
                      ）與我們聯繫。
                    </p>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
