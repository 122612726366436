/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/default-param-last */
const initialState = {
  firmInfo: {
    firmId: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.firmId || '',
    name: '',
    imgUrl: '',
  },
  platformAuthority: {
    bombmy: false,
  },
  roleAuthority: {
    order: 0,
    customer: 0,
    firmInfo: 0,
    product: 0,
  },
  userInfo: {
    userId: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.userId || '',
    userName: '',
    userPhone: '',
    userEmail: '',
    oneDeviceStatus: false,
    token: JSON.parse(localStorage.getItem('ramate_crm_auth'))?.token || '',
    authorizationToken: '',
    openPaginationStatus: false,
  },
  bombmyAuthority: {
    bombmyLive: 0,
    bombmySignup: 0,
    bombmySetting: 0,
  },
};
export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'FIRM_LOGIN': {
      state = action.payload;
      // console.log('get login data', action.payload);
      const storageData = {
        firmId: action.payload.firmInfo.firmId,
        userId: action.payload.userInfo.userId,
        token: action.payload.userInfo.token,
        deviceId: action.payload.deviceId,
      };

      localStorage.setItem('ramate_crm_auth', JSON.stringify(storageData));
      return { ...state };
    }

    case 'FIRM_ADMIN_LOGIN': {
      state.userInfo.token = action.payload.ramateAccountToken;
      state.userInfo.userId = action.payload.ramateAccountId;

      const storageData = {
        firmId: '',
        userId: action.payload.ramateAccountId,
        token: action.payload.ramateAccountToken,
        deviceId: action.payload.deviceId,
      };
      localStorage.setItem('ramate_crm_auth', JSON.stringify(storageData));
      return { ...state };
    }

    case 'FIRM_LOGOUT': {
      localStorage.clear();
      state = initialState;
      location.reload();
      return { ...state };
    }

    default:
      return state;
  }
}
